import {
  createGetReState,
  createReState,
  createReStateDispatch,
  Selector,
  useReStateSelector,
} from '@raulpesilva/re-state'
import { Component, NiceModalProps } from '../@types'
import { getModalById } from './modals'

type ShowingModals = { id: string; Component: Component; props: NiceModalProps }[]

const KEY = 'showingModals'
type Store = { [KEY]: ShowingModals }
const initialValue: ShowingModals = []

const isIgual = (a: ShowingModals, b: ShowingModals) => {
  if (a.length !== b.length) return false
  if (a.map(i => b.some(j => j.id === i.id)).some(i => !i)) return false
  return true
}

const selector: Selector<Store, ShowingModals> = ({ showingModals }) => showingModals

createReState<ShowingModals>(KEY, initialValue)
export const dispatchShowingModals = createReStateDispatch<ShowingModals>(KEY)
export const getShowingModals = createGetReState<ShowingModals>(KEY)
export const resetShowingModals = () => dispatchShowingModals(initialValue)
export const useShowingModalsSelect = () => useReStateSelector<Store, ShowingModals>(selector, isIgual)
export const removeModalById = (id: string) => dispatchShowingModals(prev => prev.filter(modal => id !== modal.id))

export const showModal = (id: string, props?: Omit<NiceModalProps, 'close'>) => {
  const modal = getModalById(id)
  if (!modal) throw new Error('This modal not exists')
  const modalId = `${Math.random() * 999}`
  const close = () => removeModalById(modalId)
  const composedProps = { ...props, close, id: modalId }
  const newModal = {
    id: modalId,
    Component: modal.component,
    props: composedProps,
  }
  dispatchShowingModals(prev => [...prev, newModal])
}
