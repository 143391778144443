import useSWF from '@raulpesilva/swf'
import { useCallback, useMemo } from 'react'
import { instanceGet } from '../../api/utils'
import { AlertsProp, useAlertsState } from '../../states'

export const useAlerts = (id?: string) => {
  const { loading, send, error, preFetch } = useSWF<{ data: AlertsProp }>('ocurrences', instanceGet)
  const [alerts, setAlerts] = useAlertsState()

  const listAlerts = useCallback(async () => {
    try {
      const { result } = await send({ noCache: false })
      if (result?.data) {
        setAlerts(result.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))
      }
    } catch {}
  }, [send, setAlerts])

  const preFetchAlerts = useCallback(async () => {
    try {
      await preFetch({ noCache: false })
    } catch {}
  }, [preFetch])

  const getAlertById = useCallback(id => alerts.find(a => a._id === id), [alerts])

  const alert = useMemo(() => getAlertById(id), [id, getAlertById])

  return { listAlerts, getAlertById, preFetchAlerts, alerts, loading, error, alert }
}
