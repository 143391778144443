import React from 'react'
import { PIN_OPTIONS, PIN_ICON } from '../../../assets/dictionaries/pins'
import * as S from './styles'
import { Position } from 'google-map-react'

interface PinProps extends Position {
  type: typeof PIN_OPTIONS[number]
  onClick?: () => void
}

export const Pin = (props: PinProps) => {
  return (
    <S.Container {...props}>
      <S.Pin src={PIN_ICON[props.type]} />
    </S.Container>
  )
}
