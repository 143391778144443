import axios, { AxiosRequestConfig } from 'axios'
import { environmentNames } from '../domain'

export const backend = {
  [environmentNames.LOCAL]: {
    BFF_URL: 'http://localhost:8080/api/v1',
    GATEWAY_URL: 'https://dev-api.yellowcam.com/api/v1',
    REACT_APP_SOCKET_ORIGIN: 'http://localhost:8080',
  },
  [environmentNames.DEVELOPMENT]: {
    BFF_URL: 'https://dev-squad-bff.yellowcam.com/api/v1',
    GATEWAY_URL: 'https://dev-api.yellowcam.com/api/v1',
    REACT_APP_SOCKET_ORIGIN: 'https://dev-squad-bff.yellowcam.com',
  },
  [environmentNames.STAGING]: {
    BFF_URL: 'https://stg-squad-bff.yellowcam.com/api/v1',
    GATEWAY_URL: 'https://stg-api.yellowcam.com/lisbon/api/v1',
    REACT_APP_SOCKET_ORIGIN: 'https://stg-squad-bff.yellowcam.com',
  },
  [environmentNames.PRODUCTION]: {
    BFF_URL: 'https://squad-bff.yellowcam.com/api/v1',
    GATEWAY_URL: 'https://api.yellowcam.com/api/v1',
    REACT_APP_SOCKET_ORIGIN: 'https://squad-bff.yellowcam.com',
  },
}

const APP_ENV = (process.env.REACT_APP_ENV ?? environmentNames.DEVELOPMENT) as keyof typeof backend

export const selectedBackend = backend[APP_ENV]

const addAuthTokenToRequest = (storage: typeof localStorage) => async (config: AxiosRequestConfig) => {
  const token = storage.getItem('ycpelotao@token')
  return { ...config, headers: { ...config.headers, 'x-access-token': token } }
}

type ConfigureAxiosInstancesProps = { storage: typeof localStorage }

const configureAxiosInstances = ({ storage }: ConfigureAxiosInstancesProps) => {
  const axiosGateway = axios.create({ baseURL: selectedBackend.GATEWAY_URL, timeout: 0 })
  const instance = axios.create({ baseURL: selectedBackend.BFF_URL, timeout: 30000 })

  axiosGateway.interceptors.request.use(addAuthTokenToRequest(storage))
  instance.interceptors.request.use(addAuthTokenToRequest(storage))

  return { instance, axiosGateway }
}

export const { instance, axiosGateway } = configureAxiosInstances({ storage: localStorage })
