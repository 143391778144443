import React, { forwardRef, Ref, useCallback, useState } from 'react'
import { BaseInputProps } from '../types'
import * as Styled from './styles'

const PrimaryInput = (
  {
    leftIcon: LeftIcon,
    variant,
    placeholder,
    value,
    onChange,
    onPressEnter,
    onClickIcon,
    type,
    name,
    disabled,
    ...rest
  }: BaseInputProps,
  ref: Ref<BaseInputProps>
) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = useCallback(() => {
    setShowPassword((prev) => !prev)
  }, [])

  const handlePressEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') onPressEnter?.()
    },
    [onPressEnter]
  )

  return (
    <Styled.Container variant={variant} {...rest}>
      {LeftIcon && (
        <Styled.IconBox onClick={onClickIcon}>
          <LeftIcon />
        </Styled.IconBox>
      )}
      <Styled.InputText
        name={name}
        variant={variant}
        placeholder={placeholder}
        type={!showPassword ? type : 'text'}
        value={value}
        onKeyDown={handlePressEnter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
        ref={ref}
        disabled={disabled}
      />
      {type === 'password' && (
        <Styled.IconBox onClick={handleTogglePassword}>
          {showPassword ? <Styled.Visible /> : <Styled.Hidden />}
        </Styled.IconBox>
      )}
    </Styled.Container>
  )
}

export default forwardRef(PrimaryInput)
