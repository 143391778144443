import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogicMiddleware } from 'redux-logic'
import { instance } from '../api'
import LocalStorage from '../local-storage'
import chatsReducer from './chats'
import chatLogics from './chats/logic'

const reducer = combineReducers({ chats: chatsReducer })
export const store = createStore(
  reducer,
  applyMiddleware(createLogicMiddleware(chatLogics({ http: instance, localStorage: LocalStorage })))
)
