import { createReState, createReStateSelect, createReStateDispatch, createGetReState } from '@raulpesilva/re-state'

interface HasNewPlaylist {}

const KEY = 'hasNewPlaylist'
const initialValue = false

export const useHasNewPlaylist = createReState<HasNewPlaylist>(KEY, initialValue)
export const useHasNewPlaylistSelect = createReStateSelect<HasNewPlaylist>(KEY)
export const dispatchHasNewPlaylist = createReStateDispatch<HasNewPlaylist>(KEY)
export const getHasNewPlaylist = createGetReState<HasNewPlaylist>(KEY)
export const resetHasNewPlaylist = () => dispatchHasNewPlaylist(initialValue)
export const setHasNewPlaylist = () => dispatchHasNewPlaylist(true)
