import { createGetReState, createReState, createReStateDispatch } from '@raulpesilva/re-state'
import { Component } from '../@types'

interface Modals {
  [id: string]: { component: Component }
}

const KEY = 'modals'
const initialValue: Modals = {}

createReState<Modals>(KEY, initialValue)
export const dispatchModals = createReStateDispatch<Modals>(KEY)
export const getAllModals = createGetReState<Modals>(KEY)
export const clearAllModals = () => dispatchModals(initialValue)

export const register = (id: string, component: Component) => {
  dispatchModals(prev => ({ ...prev, [id]: { component } }))
}

export const getModalById = (id: string) => {
  const modals = getAllModals()
  if (!modals[id]) return undefined
  return { ...modals[id] }
}
