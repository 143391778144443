import styled from 'styled-components'
import { themeBackgroundColor, themeColor, themeMarginGroup, themeRadius } from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'
import { BaseCardSkeletonProps } from './types'

export const Container = styled.div.attrs((props: BaseCardSkeletonProps) => {})`
  height: 70px;
  min-height: 70px;
  box-sizing: border-box;
  width: 360px;
  background-color: ${themeBackgroundColor};
  border-radius: ${themeRadius('normal')};
  border: 1px solid ${themeColor('secondary')};
  display: flex;
  align-items: center;
  position: relative;
  ${themeMarginGroup};
  ${animations.fromLeft}

  &:hover {
    cursor: pointer;
  }
`

export const Title = styled.div<any>`
  height: 16px;
  width: 80%;
  background-color: ${themeColor('secondary')};
  border-radius: ${themeRadius('normal')};
`

export const Description = styled.div<any>`
  height: 10px;
  width: 80%;
  margin-top: 5px;
  background-color: ${themeColor('secondary')};
  border-radius: ${themeRadius('normal')};
`

export const DescriptionShort = styled.div<any>`
  height: 10px;
  width: 60%;
  margin-top: 5px;
  background-color: ${themeColor('secondary')};
  border-radius: ${themeRadius('normal')};
`

export const Image = styled.div<any>`
  margin: 0 10px;
  box-sizing: border-box;
  width: 57px;
  height: 57px;
  min-width: 57px;
  min-height: 57px;
  border-radius: ${themeRadius('big')};
  background-color: ${themeColor('secondary')};
`

export const Wrapper = styled.div`
  flex: 1;
`
