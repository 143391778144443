export const formattedDateTimeToDateString = (date: string) => {
  const initialDateValue = new Date(date)
  const day = initialDateValue.getDate()
  const month = initialDateValue.getMonth() + 1
  const year = initialDateValue.getFullYear()

  return [day, month, year].map((d) => String(d).padStart(2, '0')).join('/')
}

export const includesInText = (text: string, value: string) => {
  return text.toLocaleLowerCase().includes(value.toLocaleLowerCase())
}

export const getDifferenceDays = (date: string) => {
  const time = new Date(date).getTime()
  const now = Date.now()
  const Difference_In_Time = now - time
  const Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)).toString()
  return parseInt(Difference_In_Days)
}

export function millisecondsToTimeString(milliseconds: number): string {
  if (milliseconds < 0) return 'Fim'
  const totalSeconds = milliseconds
  const totalMinutes = Math.floor(totalSeconds / 60)
  const totalHours = Math.floor(totalMinutes / 60)
  const days = Math.floor(totalHours / 24)

  const seconds = totalSeconds % 60
  const minutes = totalMinutes % 60
  const hours = totalHours % 24

  let time: string | number = '00s'
  if (days > 0) {
    time = `${days}d:${hours}h:${String(minutes).padStart(2, '0')}:${String(seconds.toFixed(0)).padStart(2, '0')}`
  } else if (hours > 0) {
    time = `${hours}h:${String(minutes).padStart(2, '0')}:${String(seconds.toFixed(0)).padStart(2, '0')}`
  } else if (minutes > 0) {
    time = `${String(minutes).padStart(2, '0')}:${String(seconds.toFixed(0)).padStart(2, '0')}`
  } else if (seconds > 0) {
    time = `${String(seconds.toFixed(0)).padStart(2, '0')}s`
  }
  return time
}
