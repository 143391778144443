import React, { useState, useEffect } from 'react'
import { FiSend, FiPaperclip } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { usePreview, useUser } from '../../../hook'
import { joinChat, sendMessage } from '../../../redux/chats'
import { getDifferenceDays } from '../../../utils'
import { UserAvatar } from '../../shared'
import PrimaryButton from '../../shared/Buttons/PrimaryButton'
import IlustraType from '../../shared/Ilustra-type'
import * as Styled from './styled'
import { ChatProps, Participants } from './types'

const ChatContainer = ({ item, expanded, toggleOpen, toggleExpanded, history }: ChatProps) => {
  const { handlePreview } = usePreview()
  const dispatch = useDispatch()
  const appUser = item?.participants?.find((i: Participants) => i?.type === 'app')
  const [message, setMessage] = useState('')
  const { user: self } = useUser()
  const [isParticipant, setIsParticipant] = useState(false)

  useEffect(() => {
    if (!item) {
      return
    }

    if (!item.participants) {
      return
    }

    setIsParticipant(item.participants.length === 1 || item.participants.some((p: any) => p._id === self._id))
  }, [item, self._id])

  const handleSendMessage = () => {
    dispatch(sendMessage(item._id, message))
    setMessage('')
  }

  const avatar = () => {
    const url = `https://yc-avatares.s3-sa-east-1.amazonaws.com/${appUser?.avatar}`
    if (url.slice(url.length - 3) === 'png') {
      return url
    }
    return `${url}.png`
  }

  const days = getDifferenceDays(item?.messages?.[item.messages.length - 1]?.at)

  const handleJoin = () => {
    dispatch(joinChat(item._id, self))
  }

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Click onClick={() => toggleExpanded(item)} />
        <Styled.Wrapper>
          <UserAvatar size={24} src={avatar()} />
          <Styled.User>
            <Styled.Title>{appUser?.name}</Styled.Title>
            {!!days && <Styled.Date>{days} dias</Styled.Date>}
          </Styled.User>
        </Styled.Wrapper>
        <Styled.Wrapper zIndex={true}>
          <Styled.Wrapper onClick={() => history?.push(`/alertas/alerta/${item?.meta?.occurrence?._id}`)}>
            <IlustraType type={item?.meta?.occurrence?.type} size={24} />
          </Styled.Wrapper>
          <Styled.Close onClick={() => toggleOpen(item)} />
        </Styled.Wrapper>
      </Styled.Header>
      <Styled.Body open={expanded} className="body">
        {item.messages.map((item: any, index: number) => {
          const isSquad = appUser?._id !== item.authorId
          return (
            <React.Fragment key={index}>
              <Styled.BoxMessage key={index} squad={isSquad}>
                <Styled.Message squad={isSquad}>{item.body}</Styled.Message>
              </Styled.BoxMessage>
              {!!item.attachments.length && (
                <Styled.BoxMessage
                  key={index}
                  squad={isSquad}
                  style={{ width: '50%' }}
                  onClick={() => handlePreview(item.attachments)}
                >
                  <Styled.Message
                    squad={isSquad}
                    style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <FiPaperclip style={{ marginRight: '1em' }} />
                    Anexos
                  </Styled.Message>
                </Styled.BoxMessage>
              )}
            </React.Fragment>
          )
        })}
      </Styled.Body>
      <Styled.Footer open={expanded} className="footer">
        {isParticipant ? (
          <Styled.Input
            placeholder={'Digite sua mensagem'}
            onChange={text => setMessage(text)}
            onPressEnter={handleSendMessage}
            value={message}
            disabled={!isParticipant}
          />
        ) : (
          <Styled.JoinButtonContainer>
            <PrimaryButton isLoading={false} onClick={handleJoin} label="Participar da conversa" />
          </Styled.JoinButtonContainer>
        )}
        {!!isParticipant && (
          <Styled.Send onClick={handleSendMessage}>
            <FiSend />
          </Styled.Send>
        )}
      </Styled.Footer>
    </Styled.Container>
  )
}

export default ChatContainer
