import styled, { css } from 'styled-components'
import {
  themeColorHighlight,
  themeRadius,
  themeMarginGroup,
  themePaddingGroup,
  themeColor,
  themeAnimation,
  flexGroup,
} from '../../../../assets/theme'
import { BaseStyledBoxProps } from './types'

const width = css`
  width: ${(props: BaseStyledBoxProps) => props.width}px;
`
const height = css`
  height: ${(props: BaseStyledBoxProps) => props.height}px;
`
export const Container = styled.div.attrs((props: BaseStyledBoxProps) => {})`
  width: 440px;
  background-color: ${themeColorHighlight('primary')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${themeRadius('normal')};
  position: relative;
  overflow: hidden;
  ${themeMarginGroup};
  ${themePaddingGroup};
  ${(props) => props.width && width};
  ${(props) => props.height && height};
  ${(props) => props.animation && themeAnimation};
  ${flexGroup}
`
export const Highlight1 = styled.div.attrs((props: BaseStyledBoxProps) => {})`
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  top: -200px;
  right: -200px;
  background-color: ${themeColor('primary')}0D;
`
export const Highlight2 = styled.div.attrs((props: BaseStyledBoxProps) => {})`
  position: absolute;
  background-color: ${themeColor('primary')}0D;
  width: 302px;
  height: 302px;
  border-radius: 100%;
  top: -151px;
  right: -151px;
`
