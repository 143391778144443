import React from 'react'
import * as Styled from './styles'
import { BaseNavButtonProps } from './types'

const NavButton: React.FC<BaseNavButtonProps> = ({ icon: Icon, to, ...rest }) => {
  return (
    <Styled.Container {...rest} to={to}>
      {Icon && <Icon />}
    </Styled.Container>
  )
}

export default NavButton
