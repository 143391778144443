import React, { FormEvent } from 'react'
import * as Styled from './styles'
import { BaseFormProps } from './types'

const Form: React.FC<BaseFormProps> = ({ children, onSubmit, ...rest }) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    onSubmit && onSubmit(event)
  }

  return (
    <Styled.Container {...rest} onSubmit={handleSubmit}>
      <Styled.Highlight1 />
      <Styled.Highlight2 />
      {children}
    </Styled.Container>
  )
}

export default Form
