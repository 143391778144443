import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CardCamera, CardSkeleton } from '../../components'
import { useCameras } from '../../hook/useCameras'
import { filterCameras, searchStateDispatch } from '../../states'
import * as S from './styles'

interface SearchParams {
  camera: string
}

interface SearchCamerasProps {}

const NUMBER_OF_LOADING_ITEMS = 7
const FAKE_ITEMS_TO_LOADING = [...new Array(NUMBER_OF_LOADING_ITEMS)].map(() => ({
  id: `skeleton-${Math.random() * 99999}`,
}))

export const SearchCameras = () => {
  const { cameras, loading, listCameras } = useCameras()
  const history = useHistory()
  const { camera } = useParams<SearchParams>()

  const camerasSearched = useMemo(() => {
    return filterCameras(camera)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameras, camera])

  const handleClick = (id: string) => {
    history?.push(`/cameras/camera/${id}`)
  }

  useEffect(() => {
    !cameras.length && listCameras()
    searchStateDispatch(camera)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!camerasSearched.length && cameras.length) {
    return (
      <S.Container>
        <S.NotFound>Nenhuma camera foi encontrada</S.NotFound>
      </S.Container>
    )
  }

  if (loading && !cameras.length) {
    return (
      <S.Container>
        {FAKE_ITEMS_TO_LOADING.map((item) => (
          <CardSkeleton key={item.id} />
        ))}
      </S.Container>
    )
  }

  return (
    <S.Container>
      {camerasSearched.map((camera) => {
        return (
          <CardCamera
            key={camera._id}
            title={camera.name}
            location={camera.formattedAddress}
            image={camera.sponsorLogoUrl}
            onClick={() => handleClick(camera._id)}
            restrict={camera.restrictedAccess}
            showCase
          />
        )
      })}
    </S.Container>
  )
}
