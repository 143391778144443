import styled from 'styled-components'
import { themePadding, themeRadius, variantThemeColor, variantThemeColorHighlight } from '../../../../assets/theme'
import { BaseBadgeProps } from './types'

export const Container = styled.span.attrs((props: BaseBadgeProps) => {})`
  font-size: 8px;
  background-color: ${variantThemeColor};
  color: ${variantThemeColorHighlight};
  border-radius: ${themeRadius('big')};
  width: fit-content;
  height: fit-content;
  font-weight: 700;
  padding: ${themePadding('p1')};
`
