import React from 'react'
import { ALERT_IDENTIFIERS } from '../../../assets/dictionaries/ocurrences'
import {
  HomicidioIlustra,
  IncendioIlustra,
  BuracoIlustra,
  OcupacaoIlustra,
  VandalismoIlustra,
  DescarteLixoIlustra,
  AlagamentoIlustra,
  CalcadaIlustra,
  QuedaArvoreIlustra,
  MariaPenhaIlustra,
  TraficoDrogasIlustra,
  DirecaoPerigosaIlustra,
  DesaparecimentoIlustra,
  TiroteioIlustra,
  SequestroIlustra,
  RouboIlustra,
  PerturbacaoPublicaIlustra,
  MausTratosIlustra,
  InvasaoDomicilioIlustra,
  ForagidoIlustra,
  CrimeSexuallustra,
  CrimeOdioIlustra,
  BrigaIlustra,
  OutrosIlustra,
  CriadouroIlustra,
  ComercioIlustra,
  FaltaLuzIlustra,
  AcidenteIlustra,
} from '../Icons/ilustra'
import { BaseIlustraProps } from './types'

export const types = {
  [ALERT_IDENTIFIERS.murder]: (rest: any) => <HomicidioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.fire]: (rest: any) => <IncendioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.holeInTheRoad]: (rest: any) => <BuracoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.irregularCommerce]: (rest: any) => <ComercioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.dengueBreeding]: (rest: any) => <CriadouroIlustra {...rest} />,
  [ALERT_IDENTIFIERS.irregularOccupation]: (rest: any) => <OcupacaoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.vandalism]: (rest: any) => <VandalismoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.lackOfLight]: (rest: any) => <FaltaLuzIlustra {...rest} />,
  [ALERT_IDENTIFIERS.irregularWasteDisposal]: (rest: any) => <DescarteLixoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.flood]: (rest: any) => <AlagamentoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.irregularSidewalk]: (rest: any) => <CalcadaIlustra {...rest} />,
  [ALERT_IDENTIFIERS.treeFall]: (rest: any) => <QuedaArvoreIlustra {...rest} />,
  [ALERT_IDENTIFIERS.violenceAgainstWomen]: (rest: any) => <MariaPenhaIlustra {...rest} />,
  [ALERT_IDENTIFIERS.drugTrafficking]: (rest: any) => <TraficoDrogasIlustra {...rest} />,
  [ALERT_IDENTIFIERS.dangerousDriving]: (rest: any) => <DirecaoPerigosaIlustra {...rest} />,
  [ALERT_IDENTIFIERS.disappearance]: (rest: any) => <DesaparecimentoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.shooting]: (rest: any) => <TiroteioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.kidnapping]: (rest: any) => <SequestroIlustra {...rest} />,
  [ALERT_IDENTIFIERS.theft]: (rest: any) => <RouboIlustra {...rest} />,
  [ALERT_IDENTIFIERS.disturbanceOfPublicOrder]: (rest: any) => <PerturbacaoPublicaIlustra {...rest} />,
  [ALERT_IDENTIFIERS.animalAbuse]: (rest: any) => <MausTratosIlustra {...rest} />,
  [ALERT_IDENTIFIERS.homeInvasion]: (rest: any) => <InvasaoDomicilioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.fugitive]: (rest: any) => <ForagidoIlustra {...rest} />,
  [ALERT_IDENTIFIERS.sexualCrime]: (rest: any) => <CrimeSexuallustra {...rest} />,
  [ALERT_IDENTIFIERS.hateCrime]: (rest: any) => <CrimeOdioIlustra {...rest} />,
  [ALERT_IDENTIFIERS.fight]: (rest: any) => <BrigaIlustra {...rest} />,
  [ALERT_IDENTIFIERS.accident]: (rest: any) => <AcidenteIlustra {...rest} />,
  [ALERT_IDENTIFIERS.others]: (rest: any) => <OutrosIlustra {...rest} />,
  error: (rest: any) => <OutrosIlustra color="red" {...rest} />,
}
const IlustraType: React.FC<BaseIlustraProps> = ({ type, ...rest }: any) => {
  try {
    const t = types[type](rest)
    return t || types.error(rest)
  } catch (ex) {
    return types.error(rest)
  }
}

export default IlustraType
