import styled from 'styled-components'
import { themeMargin } from '../../assets/theme'
import { BaseAlertListProps } from './types'

export const Container = styled.div.attrs((props: BaseAlertListProps) => {})`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  align-items: center;
  & > div {
    margin-top: ${themeMargin('m2')};
  }
`
