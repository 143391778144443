import styled, { css } from 'styled-components'
import { themeColor, themeRadius } from '../../../../assets/theme'

const value = css`
  & > div {
    margin-left: calc(100% - 19px);
  }
`
type ButtonProps = {
  value: string
}

export const Container = styled.button<ButtonProps & any>`
  width: 50px;
  height: 25px;
  background-color: ${themeColor('secondary')};
  border-radius: ${themeRadius('big')};
  border: none;
  display: flex;
  box-sizing: border-box;
  padding: 3px;

  &:hover {
    cursor: pointer;
  }
  & > div {
    margin-left: 0%;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0, 0.41, 0.3, 1.15);
  }
  ${props => props.value && value};
`

export const Ball = styled.div<any>`
  width: 19px;
  height: 19px;
  background-color: ${themeColor('primary')};
  border-radius: ${themeRadius('big')};
`
