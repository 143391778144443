import { darken } from 'polished'
import { IGeneralColor, IThemeProps } from '../../types'

export const themeColor =
  (color: IGeneralColor) =>
  ({ theme }: IThemeProps) =>
    theme.colors[color].color
export const themeColorHighlight =
  (color: IGeneralColor) =>
  ({ theme }: IThemeProps) =>
    theme.colors[color].highlight

export const variantThemeColor = ({ theme, variant = 'primary', highlighted }: IThemeProps) =>
  theme.colors?.[variant]?.['color']

export const variantThemeColorHighlight = ({ theme, variant = 'primary', highlighted }: IThemeProps) =>
  theme.colors?.[variant]?.['highlight']

export const themeBackgroundColor = ({ theme, highlighted }: IThemeProps) => theme.colors?.background?.['color']

export const themeBackgroundColorSecondary = ({ theme, highlighted }: IThemeProps) =>
  theme.colors?.background2?.['color']

export const themeBackgroundColorHighlight = ({ theme, highlighted }: IThemeProps) =>
  theme.colors?.background?.['highlight']

export const themeAlert = ({ theme, alert = 'urbanIssue' }: IThemeProps) =>
  `linear-gradient( 90deg,${theme.alert[alert].color}, ${theme.alert[alert].highlight})`

export const themeCamera = ({ theme, restrict }: Partial<IThemeProps & { restrict: boolean }>) =>
  restrict
    ? `linear-gradient( 90deg,${theme?.colors.darkGray.color}, ${theme?.colors.darkGray.color})`
    : `linear-gradient( 90deg,${theme?.camera.color}, ${theme?.camera.highlight})`

export const themeCameraHover = ({ theme, restrict }: IThemeProps & { restrict: boolean }) =>
  restrict
    ? `linear-gradient( 90deg,${darken(0.03, theme.colors.darkGray.color)}, ${darken(
        0.03,
        theme.colors.darkGray.color
      )})`
    : `linear-gradient( 90deg,${darken(0.03, theme.camera.color)}, ${darken(0.03, theme.camera.highlight)})`

export const themeStatus = ({ theme, status = 'PENDING' }: IThemeProps) => theme.status[status].color
