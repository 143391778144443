import React, { useMemo, useState } from 'react'
import * as Styled from './styles'
import { IOptionButton, IRenderOptions, Option } from './types'

const createRandomId = () => {
  return String(Math.floor(Math.random() * 9999))
}

const RenderOptions = ({ options, selectedItem, onSelect }: IRenderOptions) => {
  return (
    <>
      {options.map((option) => {
        return (
          <Styled.WrapperOption key={`${option._id}-option`} selectedItem={selectedItem.name === option.name}>
            {selectedItem.name === option.name && <Styled.Check />}
            <Styled.Option onMouseDown={() => onSelect(option)}>
              <Styled.OptionTitle>{option.name}</Styled.OptionTitle>
              <Styled.OptionDescription>{option.description}</Styled.OptionDescription>
            </Styled.Option>
          </Styled.WrapperOption>
        )
      })}
    </>
  )
}

const OptionButton = ({ options, ...rest }: IOptionButton) => {
  const normalizedOption = useMemo(() => {
    return options.map((option) => ({ ...option, _id: createRandomId() }))
  }, [options])

  const [toggleOption, setToggleOption] = useState(false)
  const [firstButton, setFirstButton] = useState(options?.[0] ?? { name: 'placeholder' })

  const handleClick = () => {
    firstButton?.onClick && firstButton.onClick()
  }

  const handleOnFocus = () => {
    setToggleOption(true)
  }
  const handleOnBlur = () => {
    setToggleOption(false)
  }

  const handleSelect = (op: Option) => {
    setFirstButton(op)
    setToggleOption(false)
  }
  return (
    <Styled.Container variant={firstButton?.type} {...rest}>
      <Styled.ButtonAction onClick={handleClick} {...rest}>
        {firstButton?.name}
      </Styled.ButtonAction>
      <Styled.Bar />
      {options.length > 1 && (
        <Styled.ButtonOpen onFocus={handleOnFocus} onBlur={handleOnBlur} {...rest}>
          <Styled.OpenIcon />
        </Styled.ButtonOpen>
      )}
      {toggleOption && options.length > 1 && (
        <Styled.FloatBox {...rest}>
          <RenderOptions onSelect={handleSelect} options={normalizedOption} selectedItem={firstButton} />
        </Styled.FloatBox>
      )}
    </Styled.Container>
  )
}

OptionButton.defaultProps = {
  options: [],
}

export default OptionButton
