import styled from 'styled-components'
import { themeColor, themeMargin } from '../../assets/theme'
import { BaseUserHeaderProps } from './types'

export const Container = styled.div.attrs((props: BaseUserHeaderProps) => {})`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const Name = styled.div.attrs((props: BaseUserHeaderProps) => {})`
  font-weight: 600;
  color: ${themeColor('lightGray')};
  margin: 0 ${themeMargin('m3')};
  text-transform: capitalize;
`
