import { css } from 'styled-components'
import { IPadding, IThemeProps } from '../../types'

export const padding = {
  p0: '0',
  p1: '0.25rem',
  p2: '0.5rem',
  p3: '0.75rem',
  p4: '1rem',
  p5: '1.25rem',
  p6: '1.5rem',
  p7: '1.75rem',
  p8: '2rem',
  p9: '2.5rem',
  p10: '3rem',
}

export const themePadding = (padding: IPadding) => ({ theme }: IThemeProps) => theme.padding?.[padding ?? 'p0']

const paddingAll = css`
  padding: ${({ theme, p }: IThemeProps) => theme.padding?.[p ?? 'p0']};
`

const paddingLeft = css`
  padding-left: ${({ theme, pl }: IThemeProps) => theme.padding?.[pl ?? 'p0']};
`

const paddingRight = css`
  padding-right: ${({ theme, pr }: IThemeProps) => theme.padding?.[pr ?? 'p0']};
`

const paddingTop = css`
  padding-top: ${({ theme, pt }: IThemeProps) => theme.padding?.[pt ?? 'p0']};
`

const paddingBottom = css`
  padding-bottom: ${({ theme, pb }: IThemeProps) => theme.padding?.[pb ?? 'p0']};
`
const paddingHorizontal = css`
  padding-left: ${({ theme, px }: IThemeProps) => theme.padding?.[px ?? 'p0']};
  padding-right: ${({ theme, px }: IThemeProps) => theme.padding?.[px ?? 'p0']};
`

const paddingVertical = css`
  padding-top: ${({ theme, py }: IThemeProps) => theme.padding?.[py ?? 'p0']};
  padding-bottom: ${({ theme, py }: IThemeProps) => theme.padding?.[py ?? 'p0']};
`

export const themePaddingGroup = css`
  ${(props: IThemeProps) => props.p && paddingAll};
  ${(props: IThemeProps) => props.pl && paddingLeft};
  ${(props: IThemeProps) => props.pr && paddingRight};
  ${(props: IThemeProps) => props.pt && paddingTop};
  ${(props: IThemeProps) => props.pb && paddingBottom};
  ${(props: IThemeProps) => props.px && paddingHorizontal};
  ${(props: IThemeProps) => props.py && paddingVertical};
`
