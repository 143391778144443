import { FiCheck, FiChevronDown } from 'react-icons/fi'
import styled, { css } from 'styled-components'
import { rounded, themeColor, themeColorHighlight, themeMarginGroup, variantThemeColor } from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'
import { BaseButtonProps } from '../types'
import { IRenderOptions } from './types'

export const Container = styled.div<any>`
  background-color: ${variantThemeColor};
  width: fit-content;
  min-width: 200px;
  ${props => props.grow && grow};
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  transition-duration: 250ms;
  ${props => props.rounded && rounded};
  ${themeMarginGroup};
`

const roundedAction = css`
  border-radius: 40px 0 0 40px;
`
const grow = css`
  width: 100%;
`

export const ButtonAction = styled.button.attrs((props: BaseButtonProps) => {})`
  font-weight: 600;
  background-color: transparent;
  color: ${themeColorHighlight('primary')};
  border: none;
  flex: 1;
  height: 100%;
  padding: 0 1rem 0 1rem;
  border-radius: 8px 0 0 8px;
  transition-duration: 250ms;
  ${props => props.rounded && roundedAction};

  &:hover {
    background-color: ${themeColor('pureWhite')}20;
    cursor: pointer;
  }
`

export const OpenIcon = styled(FiChevronDown).attrs({ size: 20 })`
  color: inherit;
`

export const Check = styled(FiCheck).attrs({ size: 10 })<any>`
  color: inherit;
  padding-left: 5px;
  margin-top: 20px;
  width: 15px;
  height: 15px;
  min-height: 15px;
  min-width: 15px;
`

const roundedOpen = css`
  border-radius: 0 40px 40px 0;
`

export const ButtonOpen = styled.button.attrs((props: BaseButtonProps) => {})`
  padding: 0 15px;
  border-radius: 0 8px 8px 0;
  height: 100%;
  transition-duration: 250ms;
  border: none;
  color: ${themeColorHighlight('primary')};
  background-color: transparent;
  display: flex;
  align-items: center;
  transition-duration: 250ms;
  box-sizing: border-box;
  ${props => props.rounded && roundedOpen};
  &:hover {
    background-color: ${themeColor('pureWhite')}20;
    cursor: pointer;
  }
  &:focus {
    background-color: ${themeColor('pureWhite')}20;
  }
`

export const FloatBox = styled.div<any>`
  ${props => props.grow && grow};
  position: absolute;
  top: 50px;
  left: 0;
  background-color: ${themeColor('secondary')};
  border: 0.5px solid ${themeColor('background')};
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  min-height: fit-content;
  z-index: 99;

  ${animations.growUp}
`

export const Option = styled.div`
  padding: 20px;
  flex: 1;
`

export const OptionTitle = styled.h4`
  font-weight: 600;
`

export const OptionDescription = styled.p`
  font-size: 12px;
`

const selectedItem = css`
  background-color: ${themeColor('tertiary')};
  ${Option} {
    padding-left: 5px;
  }
`

export const Bar = styled.div<any>`
  width: 1px;
  height: 80%;
  background-color: ${themeColor('secondary')};
  border-radius: 10px;
`

export const WrapperOption = styled.div.attrs((props: IRenderOptions) => {})`
  display: flex;
  transition-duration: 250ms;

  border-bottom: 0.5px solid ${themeColor('secondary')};
  color: ${themeColor('primary')};
  ${props => props.selectedItem && selectedItem}
  &:hover {
    background-color: ${themeColor('selected')};
    color: ${themeColorHighlight('pureWhite')};
    cursor: pointer;
  }
`
