import styled from 'styled-components'
import { themeMargin, themePadding } from '../../assets/theme'
import { BaseAlertOptionsProps } from './types'

export const Container = styled.div.attrs((props: BaseAlertOptionsProps) => {})`
  width: 30vw;
  height: calc(100vh - 80px);
  padding-left: ${themePadding('p5')};
  padding-bottom: ${themePadding('p5')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
`

export const WrapperButtons = styled.div.attrs((props: BaseAlertOptionsProps) => {})`
  width: calc(30vw - ${themePadding('p10')});
  margin-bottom: ${themeMargin('m2')};
  display: flex;
  flex-direction: column;
`

export const WrapperAddress = styled.div<any>`
  display: flex;
  flex: 1;
  width: calc(30vw - ${themePadding('p10')});
  max-height: 80px;
  flex-direction: column;
  justify-content: center;
`

export const AddressTitle = styled.h3<any>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  font-weight: 600;
`

export const ButtonsTitle = styled.h2<any>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.secondary.highlight};
  font-weight: 600;
  margin-bottom: ${themeMargin('m2')};
`

export const Address = styled.p<any>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  font-weight: 400;
`

export const ContainerButton = styled.button``
