import React from 'react'
import * as S from './styles'
import { BaseCardSkeletonProps } from './types'

const CardSkeleton: React.FC<BaseCardSkeletonProps> = () => {
  return (
    <S.Container>
      <S.Image />
      <S.Wrapper>
        <S.Title />
        <S.Description />
        <S.DescriptionShort />
      </S.Wrapper>
    </S.Container>
  )
}

export default CardSkeleton
