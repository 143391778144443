import React from 'react'
import * as Styled from './styles'
import { BaseSideNavProps } from './types'

const SideNav: React.FC<BaseSideNavProps> = ({ children, isList, isDetail, ...rest }) => {
  return (
    <Styled.WrapperSideBar {...rest}>
      <Styled.SideBar isList={isList} isDetail={isDetail}>
        {children}
      </Styled.SideBar>
      <Styled.WrapperSideBarCornerBottom>
        <Styled.SideBarCornerBottom />
      </Styled.WrapperSideBarCornerBottom>
    </Styled.WrapperSideBar>
  )
}

export default SideNav
