import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000099;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .react-slider__picture img {
    border-radius: 10px;
  }
  .react-slider__ul {
    min-height: 6em;
    img {
      border-radius: 4px;
    }
  }
  .react-slider__btnPrev {
    border-radius: 0px 8px 8px 0px;
  }
  .react-slider__btnNext {
    border-radius: 8px 0px 0px 8px;
  }
  .react-slider__imgZoom {
    width: 400px;
    height: 400px;
    border-radius: 10px;
  }
`

export const Header = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`
