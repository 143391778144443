import styled from 'styled-components'
import { themeMargin } from '../../assets/theme'
import { BaseChatListProps } from './types'

export const Container = styled.div.attrs((props: BaseChatListProps) => {})`
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  & > div {
    margin-top: ${themeMargin('m2')};
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`