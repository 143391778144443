import styled from 'styled-components'
import {
  Animations,
  shineHighlight,
  themeColor,
  themeColorHighlight,
  themeMargin,
  themeRadius,
} from '../../assets/theme'
import { BaseChatProps } from './types'

export const Container = styled.div.attrs((props: BaseChatProps) => {})`
  width: 329px;
  height: 40px;
  background-color: ${themeColor('secondary')};
  flex-direction: row;
  ${shineHighlight};
  color: ${themeColorHighlight('secondary')};
  border-top-left-radius: ${themeRadius('normal')};
  border-top-right-radius: ${themeRadius('normal')};
  ${Animations.show};
  margin-left: ${themeMargin('m3')};
`
