import { createAction, createActions, handleActions } from 'redux-actions'

export const CREATE_CHAT = 'CREATE_CHAT'
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS'
export const { createChat, createChatSuccess } = createActions({
  [CREATE_CHAT]: chat => ({ chat }),
  [CREATE_CHAT_SUCCESS]: chat => ({ chat }),
})
const createChatSuccessActionHandler = (state, action) => {
  return {
    ...state,
    value: [].concat(state.value).concat({ ...action.payload.chat, expanded: true, open: true }),
  }
}

export const TOGGLE_OPEN_CHAT = 'TOGGLE_OPEN_CHAT'
export const toggleOpenChat = createAction(TOGGLE_OPEN_CHAT, chat => ({ chat }))
const toggleOpenChatActionHandler = (state, action) => {
  return {
    ...state,
    value: state.value.map(c => {
      if (c._id !== action.payload.chat._id) {
        return c
      } else {
        const open = c._id === action.payload.chat._id ? !c.open : c.open
        return { ...c, open, expanded: open }
      }
    }),
  }
}

export const TOGGLE_EXPANDED_CHAT = 'TOGGLE_EXPANDED_CHAT'
export const toggleExpandedChat = createAction(TOGGLE_EXPANDED_CHAT, chat => ({ chat }))
const toggleExpandedChatActionHandler = (state, action) => {
  return {
    ...state,
    value: state.value.map(c => ({
      ...c,
      expanded: c._id === action.payload.chat._id ? !c.expanded : c.expanded,
    })),
  }
}

export const LIST_CHATS = 'LIST_CHATS'
export const LIST_CHATS_SUCCESS = 'LIST_CHATS_SUCCESS'
export const { listChats, listChatsSuccess } = createActions({
  [LIST_CHATS]: () => {},
  [LIST_CHATS_SUCCESS]: chats => ({ chats }),
})
const listChatsSuccessActionHandler = (state, action) => {
  return { ...state, value: action.payload.chats }
}

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const { sendMessage, sendMessageSuccess } = createActions({
  [SEND_MESSAGE]: (chatId, body) => ({ chatId, body }),
  [SEND_MESSAGE_SUCCESS]: (chatId, msg) => ({ chatId, msg }),
})
const sendMessageSuccessActionHandler = (state, action) => {
  const { chatId, msg } = action.payload
  return {
    ...state,
    value: state.value.map(chat => {
      if (chat._id !== chatId) {
        return chat
      }

      return { ...chat, messages: [...chat.messages, msg] }
    }),
  }
}

export const JOIN_CHAT = 'JOIN_CHAT'
export const JOIN_CHAT_SUCCESS = 'JOIN_CHAT_SUCCESS'
export const { joinChat, joinChatSuccess } = createActions({
  [JOIN_CHAT]: (chatId, user) => ({ chatId, user }),
  [JOIN_CHAT_SUCCESS]: (chatId, user) => ({ chatId, user }),
})
export const joinChatSuccessActionHandler = (state, action) => {
  const { chatId, user } = action.payload
  return {
    ...state,
    value: state.value.map(chat => {
      if (chat._id !== chatId) {
        return chat
      }

      return { ...chat, participants: [...chat.participants, user] }
    }),
  }
}

export const FETCH_OR_CREATE_CHAT = 'FETCH_OR_CREATE_CHAT'
export const FETCH_CHAT_BY_ALERT_ID_SUCCESS = 'FETCH_CHAT_BY_ALERT_ID_SUCCESS'
export const { fetchOrCreateChat, fetchChatByAlertIdSuccess } = createActions({
  [FETCH_OR_CREATE_CHAT]: (alert, participantsIds, enhancements) => ({ alert, participantsIds, enhancements }),
  [FETCH_CHAT_BY_ALERT_ID_SUCCESS]: (chat, enhancements = {}) => ({ chat, enhancements }),
})
export const fetchChatByAlertIdActionHandler = (state, action) => {
  const { chat, enhancements } = action.payload
  return {
    ...state,
    value: [].concat(state.value.filter(c => c._id !== chat._id)).concat({ ...chat, ...enhancements }),
  }
}

export const APPEND_MESSAGE_TO_CHAT = 'APPEND_MESSAGE_TO_CHAT'
export const appendMessageToChat = createAction(APPEND_MESSAGE_TO_CHAT, (chatId, message) => ({ chatId, message }))
export const appendMessageToChatActionHandler = (state, action) => {
  const { chatId, message } = action.payload
  return {
    ...state,
    value: state.value.map(chat => {
      if (chat._id !== chatId) {
        return chat
      } else {
        return { ...chat, messages: [...chat.messages, message], expanded: true, open: true }
      }
    }),
  }
}

export const INITIAL_STATE = { value: [] }
const reducer = handleActions(
  {
    [CREATE_CHAT_SUCCESS]: createChatSuccessActionHandler,
    [TOGGLE_OPEN_CHAT]: toggleOpenChatActionHandler,
    [TOGGLE_EXPANDED_CHAT]: toggleExpandedChatActionHandler,
    [LIST_CHATS_SUCCESS]: listChatsSuccessActionHandler,
    [SEND_MESSAGE_SUCCESS]: sendMessageSuccessActionHandler,
    [JOIN_CHAT_SUCCESS]: joinChatSuccessActionHandler,
    [FETCH_CHAT_BY_ALERT_ID_SUCCESS]: fetchChatByAlertIdActionHandler,
    [APPEND_MESSAGE_TO_CHAT]: appendMessageToChatActionHandler,
  },
  INITIAL_STATE
)

export default reducer
