import React from 'react'
import { useRef } from 'react'
import { Button } from '../..'
import { ContainerProps } from '../../../lib/ReAppear'
import { BaseModal } from '../../../lib/ReAppear/web/components'
import * as S from './styles'

interface ConfirmProps
  extends ContainerProps<{
    title: string
    description: string
    placeholder: string
    onConfirm?: (reason?: string) => void
    hasReason: boolean
  }> {}

export const Confirm = (props: ConfirmProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const handleConfirm = () => {
    props?.onConfirm?.(textAreaRef.current?.value)
    props.close()
  }
  return (
    <BaseModal {...props}>
      <S.Container>
        <S.Title>{props.title}</S.Title>
        <S.Description>{props.description}</S.Description>
        {props.hasReason && <S.InputField ref={textAreaRef} placeholder={props.placeholder} />}
        <S.WrapperOptions>
          <Button label="Cancelar" variant="red" onClick={props.close} aria-label="cancelar modal" />
          <Button label="Confirmar" variant="green" onClick={handleConfirm} aria-label="confirmar" />
        </S.WrapperOptions>
      </S.Container>
    </BaseModal>
  )
}
