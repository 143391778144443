import { motion } from 'framer-motion'
import { FiPaperclip, FiX } from 'react-icons/fi'
import styled, { css } from 'styled-components'
import { themeAlert, themeColor, themeRadius, themeMarginGroup, themeStatus } from '../../../assets/theme'

export const Container = styled(motion.div)<any>`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 90px;
  min-height: 90px;
  box-sizing: border-box;
  width: 360px;
  background-image: ${themeAlert};
  color: ${themeColor('pureWhite')};
  border-radius: ${themeRadius('normal')};

  ${themeMarginGroup};
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`
export const Box = styled.div`
  flex: 1;
  max-height: 60px;
  display: flex;
  align-items: center;
`
export const Image = styled.img.attrs((props: any) => {})`
  margin: 0 10px;
  box-sizing: border-box;
  width: 57px;
  height: 57px;
  min-width: 57px;
  min-height: 57px;
  border: 3px solid ${themeStatus};
  border-radius: ${themeRadius('big')};
`
export const Title = styled.h3`
  font-weight: 600;
  margin: 0;
  height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Description = styled.p`
  font-size: 10px;
  margin-top: 5px;
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Wrapper = styled.div`
  flex: 1;
`
export const Time = styled.span`
  font-size: 8px;
`
export const Date = styled.span`
  font-size: 12px;
`
const dontHaveAttachments = css`
  ${Time} {
    padding-top: 0.5rem;
  }
`
export const WrapperTime = styled.div.attrs((props: any) => {})`
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  ${props => !props.haveAttachments && dontHaveAttachments}
`
export const Attachments = styled(FiPaperclip)`
  margin: 0.5rem 0;
`
export const CloseIcon = styled(FiX)`
  font-size: 1rem;
`
export const Bar = styled.div`
  flex: 1;
  margin: 0px 10px;
  margin-top: 5px;
  max-height: 8px;
  background-color: #ffffff20;
  border-radius: 8px;
`
export const Progress = styled.div<{ progress: number }>`
  background-color: #ffffff;
  max-height: 8px;
  height: 8px;
  width: ${props => props.progress ?? 0}%;
  border-radius: 8px;
  transition-duration: 1s;
  transition-timing-function: linear;
`
export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffffff70;
  border-radius: 8px;
  transition-duration: 300ms;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`
