import React, { useEffect, useState } from 'react'
import { CardChat, Input } from '..'
import * as Styled from './styles'
import { ALERT_TYPES } from '../../assets/dictionaries/ocurrences'
import { formattedDateTime } from '../../assets/formatteds'
import { Button } from '../shared'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listChats, toggleOpenChat } from '../../redux/chats'

const ChatList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [filter, setFilter] = useState([])
  const chats = useSelector(s => s.chats.value)

  useEffect(() => {
    dispatch(listChats())
  }, [dispatch])

  useEffect(() => {
    !!chats.length && setFilter(chats)
  }, [chats])

  const verifyAvatar = user => {
    if (!!user?.avatar) {
      const avatar = user.avatar === 'no-user' ? 'no-user.png' : user.avatar
      return `https://yc-avatares.s3-sa-east-1.amazonaws.com/${avatar}`
    }
    return ''
  }

  const handleCloseList = () => history?.push('/')

  return (
    <Styled.Container>
      <Styled.Header>
        <Button label="Fechar" rounded variant="secondary" alignSelf="flex-end" onClick={handleCloseList} />
        <Input
          variant="primary"
          outlined
          rounded
          placeholder="Digite o que deseja achar"
          // onPressEnter={handleFiltered}
          value={value}
          onChange={text => setValue(text)}
        />
      </Styled.Header>
      {filter?.map((item, index) => {
        const user = item?.participants.find(i => i.type === 'app')
        const lastMessage = item.messages[item.messages.length - 1]
        return (
          !!user && (
            <CardChat
              key={index}
              userName={user?.name || ''}
              userImage={verifyAvatar(user)}
              alertImage={item.meta.occurrence.type}
              alertStatus="PENDING"
              alertType={ALERT_TYPES[item.meta.occurrence.type].label}
              message={lastMessage?.body || ''}
              time={formattedDateTime(lastMessage?.at || new Date())}
              onClick={() => {
                dispatch(toggleOpenChat(item))
              }}
            />
          )
        )
      })}
    </Styled.Container>
  )
}

export default ChatList
