import styled from 'styled-components'
import { themeMargin } from '../../assets/theme'
import { BaseCameraListProps } from './types'

export const Container = styled.div.attrs((props: BaseCameraListProps) => {})`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  align-items: center;
  max-width: 400px;
  box-sizing: border-box;
  max-height: 100%;
  & > div {
    margin-top: ${themeMargin('m2')};
  }
`
