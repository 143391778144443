import styled from 'styled-components'
import { themePadding } from '../../assets/theme'
import { BaseCamerasProps } from './types'

export const Container = styled.div.attrs((props: BaseCamerasProps) => {})`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 85vh;
  box-sizing: border-box;
  padding: ${themePadding('p2')};
  padding-bottom: ${themePadding('p5')};
  max-width: 400px;
`

export const NotFound = styled.p`
  font-size: 16px;
  margin: 16px auto 0 auto;
`
