import React, { useRef } from 'react'
import { FiSearch } from 'react-icons/fi'
import { IconButton } from '../../Buttons'
import { BaseInputProps } from '../types'
import * as Styled from './styles'
import { BaseOptionProps } from './types'

const SearchInput: React.FC<BaseOptionProps> = ({
  placeholder,
  value,
  onChange,
  onClickSearch,
  variant,
  name,
  ...rest
}) => {
  const inputRef = useRef<BaseInputProps>(null)

  return (
    <Styled.Container {...rest}>
      <Styled.CustomInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rounded
        ml="m2"
        variant={variant}
        ref={inputRef}
        name={name}
      />
      <IconButton icon={FiSearch} ml="m2" rounded variant={variant} aria-label="Search" />
    </Styled.Container>
  )
}

export default SearchInput
