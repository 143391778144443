import { IColor } from './types'

export const blue: IColor = {
  color: '#2F80ED',
  highlight: '#ffffff',
}
export const yellow: IColor = {
  color: '#FFD700',
  highlight: '#ffffff',
}
export const red: IColor = {
  color: '#e74c3c',
  highlight: '#ffffff',
}
export const green: IColor = {
  color: '#2ecc71',
  highlight: '#ffffff',
}
export const purple: IColor = {
  color: '#9b59b6',
  highlight: '#ffffff',
}
export const orange: IColor = {
  color: '#f39c12',
  highlight: '#ffffff',
}
export const lightGray: IColor = {
  color: '#f4f6fa',
  highlight: '#ffffff',
}
export const gray: IColor = {
  color: '#2A333A',
  highlight: '#ffffff',
}
export const mediumGray: IColor = {
  color: '#727f8a',
  highlight: '#ffffff',
}
export const darkGray: IColor = {
  color: '#2A2A2A',
  highlight: '#ffffff',
}

export const pureWhite: IColor = {
  color: '#ffffff',
  highlight: '#ffffff',
}
export const background: IColor = {
  color: '#0D0D0D',
  highlight: '#F0F3F8',
}
export const background2: IColor = {
  color: '#0D0D0D',
  highlight: '#FBFBFB',
}
export const primary: IColor = {
  color: '#f4f6fa',
  highlight: '#2A2A2A',
}
export const secondary: IColor = {
  color: '#2A2A2A',
  highlight: '#f4f6fa',
}
export const tertiary: IColor = {
  highlight: '#f4f6fa',
  color: '#2A333A',
}
export const fourth: IColor = {
  color: '#2A2A2A',
  highlight: '#ffffff',
}
export const success: IColor = green
export const warning: IColor = orange
export const error: IColor = red
export const selected: IColor = blue
export const disabled: IColor = lightGray
