import React, { useState } from 'react'
import * as Styled from './styles'
import { BaseSwitchProps } from './types'

const Switch: React.FC<BaseSwitchProps> = ({
  value = false,
  onClick = () => {},
  onChangeState = () => {},
  ...rest
}) => {
  const [state, setState] = useState(!!value)
  const handleChangeValue = () => {
    setState((prev) => {
      onChangeState(!prev)
      return !prev
    })
    onClick()
  }
  return (
    <Styled.Container {...rest} onClick={handleChangeValue} value={state}>
      <Styled.Ball />
    </Styled.Container>
  )
}

export default Switch
