import * as darkColors from './dark'
import * as lightColors from './light'
import { radii, margin, padding } from '../style'
import { IColor } from './types'

const urbanIssue: IColor = {
  color: '#1654fd',
  highlight: '#376CFD',
}

const crime: IColor = {
  color: '#f87f19',
  highlight: '#FF7500',
}

const others: IColor = {
  color: '#546673',
  highlight: '#647683',
}

const camera: IColor = {
  color: '#FFFD38',
  highlight: '#FFD500',
}

const PENDING: IColor = {
  color: '#ED271E',
  highlight: '#ED271E',
}

const ANALYSIS: IColor = {
  color: '#FFD700',
  highlight: '#FFD700',
}

const CLOSED: IColor = {
  color: '#32CD32',
  highlight: '#32CD32',
}

const IN_CONTENT_MODERATION: IColor = {
  color: '#0099ff',
  highlight: '#0099ff',
}

const WAITING_CONTENT_MODERATION: IColor = {
  color: '#0099ff',
  highlight: '#0099ff',
}

const REJECTED_BY_MODERATION: IColor = {
  color: '#2A2A2A',
  highlight: '#2A2A2A',
}

const CLOSING: IColor = {
  color: '#0099ff',
  highlight: '#0099ff',
}

export const statusColors = {
  PENDING,
  ANALYSIS,
  CLOSED,
  IN_CONTENT_MODERATION,
  WAITING_CONTENT_MODERATION,
  REJECTED_BY_MODERATION,
  CLOSING,
}

export const alertColors = {
  crime,
  urbanIssue,
  others,
}

export const dark = {
  colors: darkColors,
  radii,
  margin,
  padding,
  camera,
  alert: alertColors,
  status: statusColors,
}

export const light = {
  colors: lightColors,
  radii,
  margin,
  padding,
  camera,
  alert: alertColors,
  status: statusColors,
}

const theme = {
  dark,
  light,
}

export default theme
