import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 410px);
  box-sizing: border-box;
  height: 85vh;
`
export const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: transparent;
  position: relative;
  display: flex;
  flex: 1;
`
