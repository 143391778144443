import styled, { css } from 'styled-components'
import { themeColor, themeMarginGroup } from '../../../../assets/theme'
import { BaseUserAvatarProps } from './types'

const size = css`
  width: ${(props: BaseUserAvatarProps) => props.size}px;
  height: ${(props: BaseUserAvatarProps) => props.size}px;
`

export const Container = styled.img.attrs((props: any) => {})`
  background-color: ${themeColor('lightGray')};
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: none;
  ${(props) => props.size && size}
  ${themeMarginGroup}
`
