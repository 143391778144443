import React from 'react'
import * as Styled from './styles'
import { BaseStyledBoxProps } from './types'

const StyledBox: React.FC<BaseStyledBoxProps> = ({ children, ...rest }) => {
  return (
    <Styled.Container {...rest}>
      <Styled.Highlight1 />
      <Styled.Highlight2 />
      {children}
    </Styled.Container>
  )
}

export default StyledBox
