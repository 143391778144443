import { createReState, createReStateSelect, createReStateDispatch, createGetReState } from '@raulpesilva/re-state'
import { Camera, selectCamerasState } from '..'
import LocalStorage from '../../local-storage'
import { replaceAllMosaic } from '../useMosaicState'

type Playlists = Camera[][]

const KEY = 'playlists'
const initialValue: Playlists = []

const getInitialState = (): Playlists => {
  const playlistData = LocalStorage.getItem(KEY)
  if (playlistData) return JSON.parse(playlistData)
  return initialValue
}

export const usePlaylists = createReState<Playlists>(KEY, getInitialState())
export const usePlaylistsSelect = createReStateSelect<Playlists>(KEY)
export const dispatchPlaylists = createReStateDispatch<Playlists>(KEY)
export const getPlaylists = createGetReState<Playlists>(KEY)
export const savePlaylists = (playlists: Playlists) => LocalStorage.setItem(KEY, JSON.stringify(playlists))
export const resetPlaylists = () => {
  const cameras = selectCamerasState()
  let presetPlaylist = []
  for (let position = 1; position <= Math.ceil(cameras.length / 9); position++) {
    const initialPosition = position * 9 - 9
    const endPosition = position * 9
    const playlist = cameras.slice(initialPosition, endPosition)
    presetPlaylist.push(playlist)
  }
  dispatchPlaylists(presetPlaylist)
  savePlaylists(presetPlaylist)
  replaceAllMosaic(presetPlaylist[0])
}

export const addPlaylist = (playlist: Camera[]) => {
  const playlists = getPlaylists()
  const newPlaylist = [...playlists, playlist]
  savePlaylists(newPlaylist)
  dispatchPlaylists(newPlaylist)
}

export const insertManyPlaylist = (playlists: Playlists) => {
  const prevPlaylists = getPlaylists()
  const newPlaylist = [...prevPlaylists, ...playlists]
  savePlaylists(newPlaylist)
  dispatchPlaylists(newPlaylist)
}

export const removePlaylist = (position: number) => {
  const prevPlaylists = getPlaylists()
  const newPlaylist = prevPlaylists.filter((_, i) => i !== position)
  savePlaylists(newPlaylist)
  dispatchPlaylists(newPlaylist)
}
