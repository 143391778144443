import React from 'react'
import { ALERT_IMAGES } from '../../../../domain'
import { formattedDateTimeToDateString } from '../../../../utils'
import * as Styled from './styles'
import { BaseCardAlertProps } from './types'

const CardAlert: React.FC<BaseCardAlertProps> = ({
  haveAttachments,
  image = 'others',
  status,
  title,
  location,
  time,
  onClick,
  createdAt,
  ...rest
}) => {
  return (
    <Styled.Container {...rest} onClick={onClick}>
      <Styled.Image src={ALERT_IMAGES[image]} status={status} alt={title} />
      <Styled.Wrapper>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{location}</Styled.Description>
      </Styled.Wrapper>
      <Styled.WrapperTime haveAttachments={haveAttachments}>
        {haveAttachments && <Styled.Attachments />}
        <Styled.Time>{time}</Styled.Time>
        {!!createdAt && <Styled.Date>{formattedDateTimeToDateString(createdAt)}</Styled.Date>}
      </Styled.WrapperTime>
    </Styled.Container>
  )
}

CardAlert.defaultProps = {
  alert: 'crime',
}
export default CardAlert
