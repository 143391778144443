import { createGetReState, createReState, createReStateDispatch } from '@raulpesilva/re-state'
import { alertStatus } from '../../assets/dictionaries/ocurrences'
import { ALERT_TYPE_DICTIONARY } from '../../domain'
import { includesInText } from '../../utils'

export type AlertAttachment = { _id: string; extension: string; key: string; mimetype: string; location: string }

const STATUS_DTO = {
  ANALYSIS: 'analise',
  PENDING: 'pendente',
  IN_CONTENT_MODERATION: 'em moderação',
  CLOSED: 'fechado',
  WAITING_CONTENT_MODERATION: 'WAITING_CONTENT_MODERATION',
  REJECTED_BY_MODERATION: 'REJECTED_BY_MODERATION',
  CLOSING: 'CLOSING',
}

const CATEGORY_DTO = {
  urbanIssue: 'zeladoria',
  crime: 'crime',
  others: 'outros',
}

export type Alert = {
  anonymous: false
  attachments: AlertAttachment[]
  attachmentsProcessingStatus: 'PROCESSED' | undefined
  category: 'urbanIssue' | 'crime' | 'others'
  createdAt: string
  description: string
  flaggedAsInnapropriate: boolean
  formattedAddress: string
  formattedCreationDate: string
  happenedAt: string
  hasAttachments: false
  history: { _id: string; at: string; event: string; description: string; visibility: string }[]
  likes: number
  location: { type: 'Point'; coordinates: [number, number] }
  owner: string
  priority: number
  status: keyof typeof alertStatus
  trackingCode: string
  type: keyof typeof ALERT_TYPE_DICTIONARY
  userAvatar: string
  userName?: string
  visibility: 'NOT_VISIBLE' | 'VISIBLE'
  _id: string
}

export type AlertsProp = Alert[]
export const alertKey = 'alerts'

export const useAlertsState = createReState<AlertsProp>(alertKey, [] as AlertsProp)

export const dispatchAlertsState = createReStateDispatch<AlertsProp>(alertKey)
export const selectAlertsState = createGetReState<AlertsProp>(alertKey)

export const getAlertById = (alertId: Alert['_id']) => {
  const alerts = selectAlertsState()
  const selectedAlert = alerts.find(alert => alert._id === alertId)
  return selectedAlert
}

export const getLocationByAlertsId = (alertId: Alert['_id']) => {
  const selectedAlert = getAlertById(alertId)
  const location = {
    latitude: selectedAlert?.location.coordinates[0],
    longitude: selectedAlert?.location.coordinates[1],
  }
  return location
}

export const filterAlerts = (filter: string) => {
  const alerts = selectAlertsState()

  if (typeof filter === 'string' && filter.trim() === '') return alerts
  try {
    const filtered = alerts.filter(alert => {
      if (includesInText(alert.trackingCode, filter)) return true
      if (includesInText(alert.formattedAddress, filter)) return true
      if (includesInText(CATEGORY_DTO[alert.category], filter)) return true
      if (includesInText(ALERT_TYPE_DICTIONARY[alert.type], filter)) return true
      if (includesInText(STATUS_DTO[alert.status], filter)) return true
      if (alert?.userName && includesInText(alert.userName, filter)) return true
      if (alert?.attachmentsProcessingStatus && includesInText(alert.attachmentsProcessingStatus, filter)) return true

      return false
    })
    return filtered
  } catch (err) {
    console.log('error at filter Alerts', err)
    return alerts
  }
}
const moderationOptions = [
  'WAITING_CONTENT_MODERATION',
  'IN_CONTENT_MODERATION',
  'REJECTED_BY_MODERATION',
  'PENDING',
  'ANALYSIS',
  'CLOSING',
  'CLOSED',
] as const
export const updateAlertStatus = ({
  alertId,
  status,
}: {
  alertId?: Alert['_id']
  status: typeof moderationOptions[number]
}) => {
  if (!alertId) return
  const alerts = selectAlertsState()
  const newAlerts = alerts.map(alert => (alert._id === alertId ? { ...alert, status } : alert))
  dispatchAlertsState(newAlerts)
}
