import React from 'react'
import { FiPause, FiPlay } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { Button, ConfigGear, OptionInput, UserHeader } from '..'
import { useUser } from '../../hook'
import { dispatchPlaylistState, useMosaicPlayState, useSearchState } from '../../states'
import { ISearchOption } from '../shared/Inputs/OptionInput/types'
import * as Styled from './styles'
import { BaseHeaderProps } from './types'

const Header: React.FC<BaseHeaderProps> = () => {
  const [playing, setPlaying] = useMosaicPlayState()
  const history = useHistory()
  const { user } = useUser()
  const [searchValue, setSearchValue] = useSearchState()

  const handleLogout = () => {
    setPlaying((prev) => !prev)
    dispatchPlaylistState((prev) => !prev)
  }

  const handleSearch = ({ type, value }: ISearchOption) => {
    history.push(`/search/${type.toLocaleLowerCase()}/${value}`)
  }

  return (
    <Styled.WrapperHeader>
      <Styled.Container>
        <Styled.Logo to="/">Pelotão</Styled.Logo>
        {/* <StatusCount /> */}
        <OptionInput
          onClickSearch={handleSearch}
          name="searchCamera"
          value={searchValue}
          onChange={(value: string) => setSearchValue(value)}
          placeholder="Digite sua pesquisa"
          variant="darkGray"
        />
        <Button
          rounded
          label={playing ? 'Pausar' : 'Iniciar'}
          onClick={handleLogout}
          leftIcon={playing ? FiPause : FiPlay}
          aria-label="iniciar videos"
        />
        <Styled.WrapperConfig>
          {/* <NotificationBell /> */}
          <ConfigGear />
          <UserHeader name={user.name} />
        </Styled.WrapperConfig>
      </Styled.Container>
    </Styled.WrapperHeader>
  )
}

export default Header
