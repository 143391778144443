import React from 'react'
import { UserAvatar } from '../'
import * as Styled from './styles'
import { BaseUserHeaderProps } from './types'

const UserHeader: React.FC<BaseUserHeaderProps> = ({ name, ...rest }) => {
  return (
    <Styled.Container {...rest}>
      <Styled.Name>{name}</Styled.Name>
      <UserAvatar src="https://yc-avatares.s3-sa-east-1.amazonaws.com/menino-loiro.png" alt={name} />
    </Styled.Container>
  )
}

export default UserHeader
