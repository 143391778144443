import styled from 'styled-components'
import {
  rounded,
  themeColor,
  themeMarginGroup,
  themeRadius,
  variantThemeColor,
  variantThemeColorHighlight,
} from '../../../../assets/theme'
import { BaseButtonIconProps } from './types'

export const Container = styled.button.attrs((props: BaseButtonIconProps) => {})`
  height: 40px;
  width: 40px;
  border-radius: ${themeRadius('normal')};
  ${props => props.rounded && rounded};
  background-color: ${variantThemeColor};
  color: ${variantThemeColorHighlight};
  padding: 0 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${variantThemeColor};
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${themeColor('pureWhite')}00;
    position: absolute;
    top: 0;
    left: 0;
    transition-duration: 250ms;
    transform: scale(0);
  }
  &:hover {
    cursor: pointer;
    &::before {
      background-color: ${themeColor('pureWhite')}20;
      transform: scale(1);
    }
  }
  svg {
    margin: 0;
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
  }
  ${themeMarginGroup};
`
