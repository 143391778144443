import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CardCamera } from '..'
import { CardSkeleton } from '../shared'
import * as S from './styles'
import { BaseCameraListProps } from './types'

const NUMBER_OF_LOADING_ITEMS = 7
const FAKE_ITEMS_TO_LOADING = [...new Array(NUMBER_OF_LOADING_ITEMS)].map(() => ({
  id: `skeleton-${Math.random() * 99999}`,
}))

const CameraList: React.FC<BaseCameraListProps> = ({ isMap, cameras, loading, showCase }) => {
  const history = useHistory()
  const { cameraId } = useParams<{ cameraId: string }>()

  const handleClick = (id: string) => {
    if (isMap) {
      history?.push(`/maps/camera/${id}`)
      return
    }
    history?.push(`/cameras/camera/${id}`)
  }

  if (loading && !cameras.length) {
    return (
      <S.Container>
        {FAKE_ITEMS_TO_LOADING.map((item) => (
          <CardSkeleton key={item.id} />
        ))}
      </S.Container>
    )
  }

  return (
    <S.Container>
      {cameras.map((camera) => {
        return (
          <CardCamera
            key={camera._id}
            id={camera._id}
            title={camera.name}
            location={camera.formattedAddress}
            image={camera.sponsorLogoUrl}
            onClick={() => handleClick(camera._id)}
            restrict={camera.restrictedAccess}
            withLocation={isMap}
            showCase={showCase}
            selected={camera._id === cameraId}
          />
        )
      })}
    </S.Container>
  )
}

export default CameraList
