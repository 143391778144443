import React, { FormEvent, useCallback, useRef } from 'react'
import darkImage from '../../assets/images/pelotao-dark.png'
import lightImage from '../../assets/images/pelotao.png'
import { Box, Button, Form, Input } from '../../components'
import { BaseInputProps } from '../../components/shared/Inputs/types'
import { useTheme, useUser } from '../../hook'
import * as Styled from './styles'

const Login = () => {
  const { theme, toggleTheme } = useTheme()
  const { login, loading } = useUser()
  const emailRef = useRef<BaseInputProps>(null)
  const passwordRef = useRef<BaseInputProps>(null)

  const handleToggleTheme = () => {
    toggleTheme()
  }
  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (emailRef.current?.value && passwordRef.current?.value)
        await login({ email: emailRef.current.value, password: passwordRef.current.value })
    },
    [login]
  )

  return (
    <Box row flex={1} justifyContent="center" alignItems="center">
      <Styled.Theme onClick={handleToggleTheme} />
      <Box flex={0.8} justifyContent="center" alignItems="center">
        <Form p="p5" onSubmit={handleSubmit}>
          <Styled.Title>Login</Styled.Title>
          <Styled.Label>Email</Styled.Label>
          <Input
            name="email"
            ref={emailRef}
            outlined
            variant="mediumGray"
            width={380}
            type="email"
            placeholder="Digite seu email aqui"
          />
          <Styled.Label>Senha</Styled.Label>
          <Input
            name="password"
            ref={passwordRef}
            outlined
            variant="mediumGray"
            width={380}
            type="password"
            placeholder="Digite sua senha aqui"
          />
          <Button label="Entrar" isLoading={loading} type="submit" width={380} mt="m6" mb="m3" />
        </Form>
      </Box>
      <Box flex={1}>{theme === 'light' ? <Styled.Image src={lightImage} /> : <Styled.Image src={darkImage} />}</Box>
    </Box>
  )
}

export default Login
