import React from 'react'
import { FiAlertTriangle, FiMessageCircle, FiMap } from 'react-icons/fi'
import { VscDeviceCameraVideo } from 'react-icons/vsc'
import { NavButton } from '../shared'
import * as Styled from './styles'
import { BaseNavigationButtonsProps } from './types'

const NavigationButtons: React.FC<BaseNavigationButtonsProps> = () => {
  return (
    <Styled.Container>
      <NavButton icon={FiAlertTriangle} variant="secondary" to="/alertas" aria-label="navegar para alertas" />
      <NavButton icon={VscDeviceCameraVideo} variant="secondary" to="/cameras" aria-label="navegar para cameras" />
      <NavButton icon={FiMessageCircle} variant="secondary" to="/mensagens" aria-label="navegar para mensagens" />
      <NavButton icon={FiMap} variant="secondary" to="/maps" aria-label="navegar para o mapa" />
    </Styled.Container>
  )
}

export default NavigationButtons
