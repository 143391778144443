import { instance } from '../../api'
import { alertStatus } from '../../assets/dictionaries/ocurrences'
import { Alert, User, getTokenState, updateAlertStatus } from '../../states'

const MODERATION_DTO = {
  start: 'start-moderation',
  analysis: 'start-analysis',
  deny: 'deny',
  close: 'close',
  denyAndReport: 'deny-and-report',
  reportToGovernment: 'report-to-government-institution',
}

const getModerationConfig = () => {
  const token = getTokenState()
  return {
    headers: {
      'x-access-token': token,
    },
  }
}

export const startModeration = async ({ alertId }: { alertId?: Alert['_id'] }) => {
  if (!alertId) return
  try {
    await instance.put(`/ocurrences/${alertId}/${MODERATION_DTO.start}`, {}, getModerationConfig())
    updateAlertStatus({ alertId, status: alertStatus.IN_CONTENT_MODERATION })
  } catch (err) {}
}

export const approveAlert = async ({ alertId, reason }: { alertId?: Alert['_id']; reason: string }) => {
  if (!alertId) return
  try {
    await instance.put(
      `/ocurrences/${alertId}/${MODERATION_DTO.analysis}`,
      { fullApproval: true, reason },
      getModerationConfig()
    )
    updateAlertStatus({ alertId, status: alertStatus.ANALYSIS })
  } catch (err) {}
}

export const approveWithoutContentAlert = async ({ alertId, reason }: { alertId?: Alert['_id']; reason: string }) => {
  if (!alertId) return
  try {
    await instance.put(
      `/ocurrences/${alertId}/${MODERATION_DTO.analysis}`,
      { fullApproval: false, reason },
      getModerationConfig()
    )
    updateAlertStatus({ alertId, status: alertStatus.ANALYSIS })
  } catch (err) {}
}

export const denyAlert = async ({ alertId, reason }: { alertId?: Alert['_id']; reason: string }) => {
  if (!alertId) return
  try {
    await instance.put(`/ocurrences/${alertId}/${MODERATION_DTO.deny}`, { reason }, getModerationConfig())
    updateAlertStatus({ alertId, status: alertStatus.REJECTED_BY_MODERATION })
  } catch (err) {}
}

export const denyContentAndReportUser = async ({
  alertId,
  reason,
  userId,
}: {
  alertId?: Alert['_id']
  reason: string
  userId?: User['_id']
}) => {
  if (!alertId && !userId) return
  try {
    await instance.put(
      `/ocurrences/${alertId}/${MODERATION_DTO.denyAndReport}`,
      { userId, reason },
      getModerationConfig()
    )
    updateAlertStatus({ alertId, status: alertStatus.REJECTED_BY_MODERATION })
  } catch (err) {}
}

export const closeOccurrence = async ({ alertId, observations }: { alertId?: Alert['_id']; observations: string }) => {
  if (!alertId) return
  try {
    await instance.put(`/ocurrences/${alertId}/${MODERATION_DTO.close}`, { observations }, getModerationConfig())
    updateAlertStatus({ alertId, status: alertStatus.CLOSED })
  } catch (err) {}
}

export const reportGovernment = async ({ alertId, reason }: { alertId?: Alert['_id']; reason: string }) => {
  if (!alertId) return
  try {
    await instance.put(`/ocurrences/${alertId}/${MODERATION_DTO.reportToGovernment}`, { reason }, getModerationConfig())
    updateAlertStatus({ alertId, status: alertStatus.CLOSED })
  } catch (err) {}
}
