import { createReState } from '@raulpesilva/re-state'

export type Chat = {
  allowMessages: boolean
  _id: string
  meta: { occurrence: { type: string; category: string, _id: string } }
  messages: [{ at: string , body: string}]
  participants: [{ avatar: string; name: string; type: string, owner: string }]
}

export const useChatsState = createReState<Chat[]>('chats', [])

type OpenChats = {
  allowMessages: boolean
  _id: string
  meta: { occurrence: { type: string; category: string } }
  messages: [{ at: string , body: string}]
  participants: [{ avatar: string; name: string; type: string }]
  open: boolean
}

export const useOpenChatssState = createReState<OpenChats[]>('openChats', [])
