import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { themeColor, themeColorHighlight, themePadding, themeRadius, Animations } from '../../../../assets/theme'
import { BaseNavButtonProps } from './types'

export const Container = styled(NavLink).attrs((props: BaseNavButtonProps) => {})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: ${themePadding('p2')};
  border-radius: ${themeRadius('normal')};
  color: ${themeColorHighlight('secondary')};
  ${Animations.appear};

  background-color: ${themeColor('secondary')}00;
  transition-duration: 250ms;
  &:hover {
    cursor: pointer;
    background-color: ${themeColorHighlight('secondary')};
    color: ${themeColor('secondary')};
  }

  & > svg {
    width: 22px;
    height: 22px;
  }
`
