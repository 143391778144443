import useSWF from '@raulpesilva/swf'
import { useCallback } from 'react'
import { instanceGet } from '../../api/utils'
import { CamerasProp, useCamerasState } from '../../states/useCameraState'
import { getMosaic, replaceAllMosaic } from '../../states/useMosaicState'

const keepCameras = ['yc-spo-0000']

export const useCameras = () => {
  const { loading, send, error, preFetch } = useSWF<{ data: { cameras: CamerasProp } }>(
    '/dashboard?latitude=-23.5489&longitude=-46.6388&radius=1000000',
    instanceGet
  )
  const [cameras, setCameras] = useCamerasState()

  const listCameras = useCallback(async () => {
    try {
      const { result } = await send({ noCache: false })
      if (result?.data?.cameras) {
        const camerasToShow = result.data.cameras.filter((camera) => {
          const sponsor = camera.sponsor.toLocaleLowerCase()
          const name = camera.name.toLocaleLowerCase()
          if (sponsor === 'goodu') return false
          if (sponsor.includes('Laboratorio Yellowcam'.toLocaleLowerCase())) return false
          if (sponsor === 'conecta empreendimentos' && !keepCameras.includes(name)) return false
          return true
        })
        setCameras(camerasToShow)
        !getMosaic().length && replaceAllMosaic(camerasToShow)
      }
    } catch {}
  }, [send, setCameras])

  const preFetchCameras = useCallback(async () => {
    try {
      await preFetch({ noCache: false })
    } catch {}
  }, [preFetch])

  const getCameraById = useCallback(
    (id) => {
      return cameras?.find((a) => a._id === id)
    },
    [cameras]
  )

  return { listCameras, getCameraById, preFetchCameras, cameras, loading, error }
}
