import { useCallback } from 'react'
import LocalStorage from '../../local-storage'
import { useThemeState } from '../../states'

export const useTheme = () => {
  const [theme, setTheme] = useThemeState()

  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    setTheme(newTheme)
    LocalStorage.setItem('theme', newTheme)
  }, [setTheme, theme])

  return { toggleTheme, theme, setTheme }
}
