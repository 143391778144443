import { FaBell } from 'react-icons/fa'
import styled from 'styled-components'
import { themeColor, themeColorHighlight, themeMargin } from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'

export const Container = styled.div.attrs((props) => {})`
  position: relative;
  width: fit-content;
  height: 24px;
`
export const Bell = styled(FaBell).attrs((props: any) => {})`
  color: ${themeColor('lightGray')};
  margin: 0 ${themeMargin('m5')};
  ${animations.appear}
  &:hover {
    cursor: pointer;
    color: ${themeColorHighlight('secondary')}dc;
  }
`
export const ScrollNotification = styled.div.attrs((props: any) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  & > div + div {
    margin-top: ${themeMargin('m2')};
  }
`

export const Title = styled.h3.attrs((props: any) => {})`
  color: ${themeColor('lightGray')};
  margin: ${themeMargin('m4')};
`
