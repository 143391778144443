import styled, { css } from 'styled-components'
import { themePaddingGroup, themeMarginGroup, flexGroup, themeBackgroundColor } from '../../../../assets/theme'
import { BaseBoxProps } from './types'

const BaseBox = css`
  box-sizing: border-box;
`
const Width = css`
  width: ${({ w }: BaseBoxProps) => w};
`
const Height = css`
  height: ${({ h }: BaseBoxProps) => h};
`

export const Container = styled.div.attrs((props: BaseBoxProps) => {})`
  ${themePaddingGroup};
  ${themeMarginGroup};
  ${BaseBox};
  background-color: ${themeBackgroundColor};
  ${flexGroup};
  ${(props) => props?.w && Width};
  ${(props) => props?.h && Height};
`
