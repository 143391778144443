import { css } from 'styled-components'
import { IRadius, IThemeProps } from '../../types'

export const radii = {
  small: '4px',
  normal: '8px',
  medium: '12px',
  large: '16px',
  big: '32px',
}

export const themeRadius =
  (radius: IRadius) =>
  ({ theme }: Partial<IThemeProps>) =>
    theme?.radii?.[radius]

export const rounded = css`
  border-radius: ${themeRadius('big')};
  &:hover {
    &::before {
      border-radius: ${themeRadius('big')};
    }
  }
`
