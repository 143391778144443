import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 80px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  overflow-y: auto;
  div {
    margin-left: 8px;
  }
  div + div + div + div + div {
    margin-left: 8px;
    margin-top: 8px;
  }
`

export const NotFound = styled.p`
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-right: 100px;
`
