import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { alertStatus } from '../../assets/dictionaries/ocurrences'
import { Button, CustomMap, OptionButton, Wrapper } from '../../components'
import { Pin } from '../../components/shared/Pin'
import { useAlerts, useUser } from '../../hook'
import { useModal } from '../../lib/ReAppear'
import { fetchOrCreateChat, listChats } from '../../redux/chats'
import {
  approveAlert,
  approveWithoutContentAlert,
  closeOccurrence,
  denyAlert,
  denyContentAndReportUser,
  reportGovernment,
  startModeration,
} from '../../services'
import * as S from './styles'

const defaultProps = {
  center: {
    lat: 38.70371139590018,
    lng: -9.291614980594003,
  },
}

const AlertOptions = () => {
  const { alertId } = useParams()
  const { getAlertById, listAlerts, alerts, loading } = useAlerts()
  const [alert, setAlert] = useState()
  const dispatch = useDispatch()
  const { user } = useUser()

  const { sendModal } = useModal()

  useEffect(() => {
    !(alerts.length > 0) && listAlerts()
    setAlert(getAlertById(alertId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAlertById])

  useEffect(() => {
    dispatch(listChats())
  }, [dispatch])

  const handleInitRevision = () => {
    startModeration({ alertId: alert?._id })
  }

  const handleAprove = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description: 'Após aprovação, este alerta ficará visível para todos os usuários do aplicativo.',
      placeholder: 'Observações adicionais. (Opcional)',
      onConfirm: reason => approveAlert({ alertId: alert?._id, reason }),
    })
  }

  const handlePartialAprove = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description:
        'Após aprovação parcial, este anexo ficará disponível para todos os usuários do aplicativo, mas seus anexos serão sensurados.',
      placeholder: 'Observações adicionais. (Opcional)',
      onConfirm: reason => approveWithoutContentAlert({ alertId: alert?._id, reason }),
    })
  }

  const handleReject = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description: 'Após rejeição, este alerta será descartado da lista de alertas.',
      placeholder: 'Digite o motivo aqui!',
      onConfirm: reason => denyAlert({ alertId: alert?._id, reason }),
    })
  }

  const handleRejectAndReportUser = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description:
        'Após rejeição, o alerta será descartado da lista de alertas e o usuário que o criou terá a função de criar alertas desabilitada até uma análise específica do caso.',
      placeholder: 'Digite o motivo aqui!',
      onConfirm: reason => denyContentAndReportUser({ alertId: alert?._id, reason, userId: alert?.owner }),
    })
  }

  const handleFinish = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description: 'Após encerrar o alerta ele sumirá da listagem de alerta.',
      placeholder: 'Observações adicionais. (Opcional)',
      onConfirm: observations => closeOccurrence({ alertId: alert?._id, observations }),
      hasReason: true,
    })
  }

  const handleReportGovernment = () => {
    sendModal({
      name: 'confirm',
      title: 'Atenção',
      description: 'Esta ação diz ao usuário que todas as medidas ja foram tomadas.',
      placeholder: 'Observações adicionais. (Opcional)',
      onConfirm: reason => reportGovernment({ alertId: alert?._id, reason }),
    })
  }

  const handleChat = () => dispatch(fetchOrCreateChat(alert, [user._id, alert.owner], { expanded: true, open: true }))

  const needModeration = useMemo(() => {
    if (alert?.status === alertStatus.IN_CONTENT_MODERATION) return true
    if (alert?.status === alertStatus.WAITING_CONTENT_MODERATION) return true
    return false
  }, [alert])

  const showModerationTitle = useMemo(() => {
    if (alert?.status === alertStatus.IN_CONTENT_MODERATION) return true
    if (alert?.status === alertStatus.PENDING) return true
    return false
  }, [alert])

  const showFinishOptions = useMemo(() => {
    if (alert?.status === alertStatus.ANALYSIS) return true
    return false
  }, [alert])

  const alertIsClosed = useMemo(() => {
    if (alert?.status === alertStatus.CLOSED) return true
    return false
  }, [alert])

  return (
    <S.Container>
      {!alertIsClosed && (
        <S.WrapperButtons>
          {showModerationTitle && <S.ButtonsTitle>Opções de moderação</S.ButtonsTitle>}
          {alert?.status === alertStatus.PENDING && (
            <Button label="Iniciar Moderação" style={{ width: '100%' }} onClick={handleInitRevision} />
          )}
          {showFinishOptions && <S.ButtonsTitle>Opções de atendimento</S.ButtonsTitle>}
          {showFinishOptions && (
            <OptionButton
              grow
              options={[
                {
                  name: 'Abrir chat',
                  description: 'Está ação declara que foi protocolado no órgão competente',
                  onClick: handleChat,
                },
                {
                  type: 'blue',
                  name: 'Reportar ao órgão competente',
                  description: 'Está ação declara que foi protocolado no órgão competente',
                  onClick: handleReportGovernment,
                },
                {
                  name: 'Encerrar atendimento',
                  description: 'Está ação declara que todas as ações possiveis foram tomadas.',
                  onClick: handleFinish,
                },
              ]}
            />
          )}
          {needModeration && (
            <OptionButton
              grow
              options={[
                {
                  type: 'green',
                  name: 'Aprovar',
                  description: 'Aprovar o alerta e torná-lo disponível no aplicativo.',
                  onClick: handleAprove,
                },
                {
                  type: 'warning',
                  name: 'Aprovar parcialmente',
                  description:
                    'Aprovar parcialmente o alerta, tornado somente sua descrição visível, mas seus anexos censurados.',
                  onClick: handlePartialAprove,
                },
                {
                  type: 'error',
                  name: 'Rejeitar',
                  description: 'Rejeitar o alerta e descartá-lo da lista.',
                  onClick: handleReject,
                },
                {
                  type: 'blue',
                  name: 'Reportar ao órgão competente',
                  description: 'Rejeitar o alerta e descartá-lo da lista.',
                  onClick: handleReportGovernment,
                },
                {
                  name: 'Rejeitar e suspender usuário',
                  description:
                    'Rejeitar o alerta e descartá-lo da lista. Ainda, reportar o usuário que o criou e suspender sua conta.',
                  onClick: handleRejectAndReportUser,
                },
              ]}
            />
          )}
        </S.WrapperButtons>
      )}

      <S.WrapperAddress>
        <S.AddressTitle>Endereço</S.AddressTitle>
        <S.Address>{alert?.formattedAddress}</S.Address>
      </S.WrapperAddress>
      <Wrapper flex={1} style={styles.map}>
        {!loading && (
          <CustomMap
            defaultCenter={{
              lat: alert?.location.coordinates[0] ?? defaultProps.center.lat,
              lng: alert?.location.coordinates[1] ?? defaultProps.center.lng,
            }}
          >
            {alert && <Pin lat={alert.location.coordinates[0]} lng={alert.location.coordinates[1]} type={alert.type} />}
          </CustomMap>
        )}
      </Wrapper>
    </S.Container>
  )
}

const styles = { map: { borderRadius: '8px', overflow: 'hidden' } }
export default AlertOptions
