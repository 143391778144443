import styled from 'styled-components'
import { themeColor, themeMargin, themeRadius } from '../../../../assets/theme'
import { BaseStatusCountProps } from './types'

export const Container = styled.div.attrs((props: BaseStatusCountProps) => {})`
  display: flex;
  align-items: center;
`

export const Status = styled.div.attrs((props: BaseStatusCountProps) => {})`
  width: 21px;
  height: 21px;
  border-radius: ${themeRadius('small')};
  background-color: ${themeColor('blue')};
`
export const Count = styled.span.attrs((props: BaseStatusCountProps) => {})`
  font-weight: 600;
  color: ${themeColor('lightGray')};
  margin-left: ${themeMargin('m1')};
  margin-right: ${themeMargin('m3')};
  padding-top: 2px;
`
