export const PIN_ICON = {
  camera: require('../images/pins/PinCamera@3x.png') as string,
  cameraRestrict: require('../images/pins/PinRestrict@3x.png') as string,
  theft: require('../images/pins/roubo@3x.png') as string,
  murder: require('../images/pins/homicidio@3x.png') as string,
  dangerousDriving: require('../images/pins/direcao@3x.png') as string,
  drugTrafficking: require('../images/pins/trafico@3x.png') as string,
  kidnapping: require('../images/pins/sequestro@3x.png') as string,
  violenceAgainstWomen: require('../images/pins/penha@3x.png') as string,
  sexualCrime: require('../images/pins/sexual@3x.png') as string,
  shooting: require('../images/pins/tiroteio@3x.png') as string,
  animalAbuse: require('../images/pins/animais@3x.png') as string,
  fight: require('../images/pins/briga@3x.png') as string,
  homeInvasion: require('../images/pins/invasao@3x.png') as string,
  fugitive: require('../images/pins/foragido@3x.png') as string,
  disturbanceOfPublicOrder: require('../images/pins/orderPublica@3x.png') as string,
  hateCrime: require('../images/pins/odio@3x.png') as string,
  fire: require('../images/pins/incendio@3x.png') as string,
  flood: require('../images/pins/alagamento@3x.png') as string,
  treeFall: require('../images/pins/arvore@3x.png') as string,
  accident: require('../images/pins/acidente@3x.png') as string,
  irregularWasteDisposal: require('../images/pins/lixo@3x.png') as string,
  irregularSidewalk: require('../images/pins/calcada@3x.png') as string,
  lackOfLight: require('../images/pins/luz@3x.png') as string,
  holeInTheRoad: require('../images/pins/buraco@3x.png') as string,
  irregularOccupation: require('../images/pins/ocupacaoIrregular@3x.png') as string,
  irregularCommerce: require('../images/pins/comercio@3x.png') as string,
  vandalism: require('../images/pins/vandalismo@3x.png') as string,
  others: require('../images/pins/others@3x.png') as string,
  dengueBreeding: require('../images/pins/dengue@3x.png') as string,
  disappearance: require('../images/pins/desaparecimento@3x.png') as string,
  clusterCamera: require('../images/pins/cluster-camera@3x.png') as string,
  clusterBoth: require('../images/pins/cluster-both@3x.png') as string,
} as const

export const PIN_OPTIONS = [
  'camera',
  'cameraRestrict',
  'theft',
  'murder',
  'dangerousDriving',
  'drugTrafficking',
  'kidnapping',
  'violenceAgainstWomen',
  'sexualCrime',
  'shooting',
  'animalAbuse',
  'fight',
  'homeInvasion',
  'fugitive',
  'disturbanceOfPublicOrder',
  'hateCrime',
  'fire',
  'flood',
  'treeFall',
  'accident',
  'irregularWasteDisposal',
  'irregularSidewalk',
  'lackOfLight',
  'holeInTheRoad',
  'irregularOccupation',
  'irregularCommerce',
  'vandalism',
  'others',
  'dengueBreeding',
  'disappearance',
  'clusterCamera',
  'clusterBoth',
] as const
