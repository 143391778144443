import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useShowingModalsSelect } from './showingModals'

export const NiceModalProvider: React.FC = ({ children }) => {
  const modals = useShowingModalsSelect()
  return (
    <>
      {children}
      <AnimatePresence>
        {modals.map(({ Component, props, id }, index) => {
          return <Component key={id} id={id} {...props} index={index} />
        })}
      </AnimatePresence>
    </>
  )
}
