import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { themeBackgroundColor, themeRadius, themePadding, themeColor, themeColorHighlight } from '../../assets/theme'
import { BaseHeaderProps } from './types'

export const Container = styled.header.attrs((props: BaseHeaderProps) => {})`
  background-color: ${themeBackgroundColor};
  border-bottom-left-radius: ${themeRadius('big')};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${themePadding('p7')};
  padding-bottom: 10px;
  @media only screen and (max-width: 1200px) {
    & > button {
      display: none;
    }
  }
`
export const WrapperHeader = styled.nav.attrs((props: BaseHeaderProps) => {})`
  background-color: ${themeColorHighlight('primary')};
  display: flex;
  height: 80px;
  max-height: 80px;
  width: 100%;
`

export const WrapperConfig = styled.div`
  display: flex;
  align-items: center;
`
export const Logo = styled(NavLink).attrs((props: BaseHeaderProps) => {})`
  color: ${themeColor('primary')};
  font-size: 36px;
  font-weight: 700;
  text-decoration: none;
`
