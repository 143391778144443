import React, { useCallback, useEffect, useState } from 'react'
import { StyledBox, Wrapper } from '../..'
import { useTheme, useUser } from '../../../../hook'
import { Button, Switch } from '../../Buttons'
import * as Styled from './styles'

const RenderBox = ({ handleToggleOpenConfig }: { handleToggleOpenConfig: (any: any) => void }) => {
  const { toggleTheme, theme } = useTheme()
  const { logout } = useUser()

  useEffect(() => {
    window.addEventListener('click', handleToggleOpenConfig)
    return () => window.removeEventListener('click', handleToggleOpenConfig)
  }, [handleToggleOpenConfig])

  return (
    <StyledBox
      height={600}
      width={384}
      style={{ position: 'absolute', top: 40, right: '-2em', overflowY: 'auto', zIndex: 99 }}
      animation="growUp"
    >
      <Styled.Title>Aparência</Styled.Title>
      <Wrapper row justifyContent="space-between" mx="m4">
        <Styled.Text>Tema {theme === 'light' ? 'Escuro' : 'Claro'}</Styled.Text>
        <Switch value={theme === 'dark'} onClick={toggleTheme} />
      </Wrapper>
      <Styled.Title>Trocar senha</Styled.Title>

      <Button label="Sair" mx="auto" variant="red" rounded onClick={logout} />
    </StyledBox>
  )
}

const ConfigGear = () => {
  const [openNotification, setOpenNotification] = useState(false)

  const handleToggleOpenConfig = useCallback(() => {
    setOpenNotification((prev) => !prev)
  }, [])

  const handleStopEvent = (event: Event) => {
    event.stopPropagation()
  }

  return (
    <Styled.Container onClick={handleStopEvent}>
      <Styled.Bell size={24} onClick={handleToggleOpenConfig} />
      {openNotification && <RenderBox handleToggleOpenConfig={handleToggleOpenConfig} />}
    </Styled.Container>
  )
}

export default ConfigGear
