import React, { useRef, useState } from 'react'
import { FiChevronDown, FiSearch } from 'react-icons/fi'
import { Input } from '..'
import { Button, IconButton } from '../../Buttons'
import { BaseInputProps } from '../types'
import * as S from './styles'
import { BaseOptionProps } from './types'

const OPTIONS = ['Camera', 'Alerta'] as const
type TypeOptions = typeof OPTIONS[number]

const OptionInput: React.FC<BaseOptionProps> = ({
  placeholder,
  value,
  onChange,
  onClickSearch,
  variant,
  name,
  ...rest
}) => {
  const inputRef = useRef<BaseInputProps>(null)
  const [type, setType] = useState<TypeOptions>('Camera')
  const [inputValue, setInputValue] = useState(value ?? '')
  const [openOptions, setOpenOptions] = useState(false)

  const handleSearch = () => {
    onClickSearch?.({ type, value })
  }

  const handleChangeValue = (changedValue: string) => {
    setInputValue(changedValue)
    onChange?.(changedValue)
  }

  const handleSelectType = (type: TypeOptions) => {
    setType(type)
    setOpenOptions(false)
  }

  return (
    <S.Container {...rest}>
      <Button
        onClick={() => setOpenOptions((prev) => !prev)}
        label={type}
        rightIcon={FiChevronDown}
        rounded
        style={{ borderBottomRightRadius: 8, minWidth: 120 }}
        ml="m2"
        variant={variant}
      />
      {openOptions && (
        <S.Options>
          {OPTIONS.map((option) => {
            if (option === type) return null
            return (
              <S.Option key={option} onClick={() => handleSelectType(option)}>
                {option}
              </S.Option>
            )
          })}
        </S.Options>
      )}
      <Input
        style={{ borderTopLeftRadius: 8 }}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChangeValue}
        onPressEnter={handleSearch}
        rounded
        ml="m2"
        variant={variant}
        ref={inputRef}
        name={name}
      />
      <IconButton icon={FiSearch} ml="m2" rounded onClick={handleSearch} variant={variant} aria-label="Search" />
    </S.Container>
  )
}

export default OptionInput
