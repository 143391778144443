import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  z-index: 999;
`
