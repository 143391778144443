import { css } from 'styled-components'

const growUp = css`
  animation: growUp 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  @keyframes growUp {
    from {
      transform: scale(0) translateY(-200px);
    }
    to {
      transform: scale(1) translateY(0);
    }
  }
`
const growDown = css`
  animation: growUp 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  @keyframes growUp {
    from {
      transform: scale(0) translateY(200px);
    }
    to {
      transform: scale(1) translateY(0);
    }
  }
`
const expandY = css`
  animation: expandY 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  transform-origin: top;
  @keyframes expandY {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
`
const expandX = css`
  animation: expandX 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  transform-origin: left;
  @keyframes expandX {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
`
const appear = css`
  animation: appear 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  transform-origin: center;
  @keyframes appear {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`

const show = css`
  animation: show 500ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  transform-origin: center;
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const fromLeft = css`
  animation: fromLeft 250ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  transform-origin: center;
  transform: translateX(-40px);
  @keyframes fromLeft {
    from {
      transform: translateX(-40px);
    }
    to {
      transform: translateX(0);
    }
  }
`

const rotate = css`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export default {
  growUp,
  rotate,
  growDown,
  expandY,
  expandX,
  appear,
  show,
  fromLeft,
}
