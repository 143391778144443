import { useCallback } from 'react'
import { usePreviewState } from '../../states'

export const usePreview = () => {
  const [preview, setPreview] = usePreviewState()

  const handlePreview = useCallback(
    data => {
      setPreview(data)
    },
    [setPreview]
  )

  return {
    handlePreview,
    preview,
  }
}
