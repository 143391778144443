import React from 'react'
import * as S from './styles'
import { Props } from 'google-map-react'
import { useTheme } from '../../hook'

interface CustomMapProps extends Props {
  children?: React.ReactNode
}

export const CustomMap = (props: CustomMapProps) => {
  const { theme } = useTheme()
  return <S.Container {...props} darkMode={theme === 'dark'} />
}
