import styled, { css } from 'styled-components'
import { themeBackgroundColor, themeColorHighlight, themeRadius } from '../../assets/theme'
import { BaseSideNavProps } from './types'

const isList = css`
  width: 400px;
`
const isDetail = css`
  width: 70vw;
`
export const WrapperSideBar = styled.aside.attrs((props: BaseSideNavProps) => {})`
  height: calc(100vh - 80px);
  width: 60px;
  min-width: 60px;
  background-color: transparent;
  border-top-right-radius: ${themeRadius('big')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const SideBar = styled.aside.attrs((props: BaseSideNavProps) => {})`
  z-index: 5;
  height: 85vh;
  max-height: 85vh;
  width: 60px;
  display: flex;
  flex-direction: column;
  min-width: 60px;
  box-sizing: border-box;
  background-color: ${themeColorHighlight('primary')};
  border-top-right-radius: ${themeRadius('big')};
  border-bottom-right-radius: ${themeRadius('big')};
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0, 0.41, 0.3, 1.15);
  ${props => props.isList && isList}
  ${props => props.isDetail && isDetail}
`

export const WrapperSideBarCornerBottom = styled.aside.attrs((props: BaseSideNavProps) => {})`
  height: calc(15vh - 80px);
  width: 60px;
  min-width: 60px;
  background-color: ${themeColorHighlight('primary')};
  border-top-right-radius: ${themeRadius('big')};
`
export const SideBarCornerBottom = styled.aside.attrs((props: BaseSideNavProps) => {})`
  height: calc(15vh - 80px);
  width: 60px;
  min-width: 60px;
  background-color: ${themeBackgroundColor};
  border-top-left-radius: ${themeRadius('big')};
`
