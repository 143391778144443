const prefix = 'ycpelotao'

class LocalStorage {
  static setItem(key, value) {
    localStorage.setItem(`${prefix}@${key}`, value)
  }

  static getItem(key) {
    return localStorage.getItem(`${prefix}@${key}`)
  }

  static removeItem(key) {
    localStorage.removeItem(`${prefix}@${key}`)
  }
}

export default LocalStorage
