import { createReState, createGetReState, createReStateSelect, createReStateDispatch } from '@raulpesilva/re-state'
import { includesInText } from '../../utils'

export type Camera = {
  closedGroups: []
  enabled: boolean
  formattedAddress: string
  location: { type: string; coordinates: [number, number] }
  name: string
  restrictedAccess: boolean
  sponsor: string
  sponsorLogoUrl: string
  streamingName: string
  streamingURL: string
  _id: string
}
export type CamerasProp = Camera[]

export const camerasKey = 'cameras'

export const useCamerasState = createReState<CamerasProp>(camerasKey, [] as CamerasProp)

export const useCameraPlayback = createReState<string>('playback')
export const useSelectCameraPlayback = createReStateSelect<string>('playback')
export const dispatchPlayback = createReStateDispatch<string>('playback')

export const selectCamerasState = createGetReState<CamerasProp>(camerasKey)

export const getCameraById = (cameraId?: Camera['_id']) => {
  const cameras = selectCamerasState()
  const selectedCamera = cameras?.find(camera => camera._id === cameraId)
  return selectedCamera
}

export const getLocationByCameraId = (cameraId: Camera['_id']) => {
  const selectedCamera = getCameraById(cameraId)
  const location = {
    latitude: selectedCamera?.location.coordinates[0],
    longitude: selectedCamera?.location.coordinates[1],
  }
  return location
}

export const filterCameras = (filter: string) => {
  const cameras = selectCamerasState()

  if (filter.trim() === '') return cameras

  const filtered = cameras.filter(camera => {
    if (includesInText(camera.name, filter)) return true
    if (includesInText(camera.formattedAddress, filter)) return true
    if (includesInText(camera.sponsor, filter)) return true

    return false
  })
  return filtered
}
