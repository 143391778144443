import styled from 'styled-components'
import { themePadding } from '../../assets/theme'
import { BaseNavigationButtonsProps } from './types'

export const Container = styled.div.attrs((props: BaseNavigationButtonsProps) => {})`
  box-sizing: border-box;
  flex: 1;
  padding: ${themePadding('p10')} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 60px;
`
