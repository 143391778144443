import React from 'react'
import * as Styled from './styles'
import { BaseButtonIconProps } from './types'
import { BsQuestionCircle } from 'react-icons/bs'

const IconButton: React.FC<BaseButtonIconProps> = ({ icon: Icon, onClick, ...rest }) => {
  return (
    <Styled.Container {...rest} onClick={onClick}>
      {Icon && <Icon />}
    </Styled.Container>
  )
}

IconButton.defaultProps = {
  icon: BsQuestionCircle,
}

export default IconButton
