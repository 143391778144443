import { createReState, createReStateDispatch } from '@raulpesilva/re-state'

export type User = {
  name: string
  _id: string
  email: string
  type: string
  iat: number
  avatar: string
  roles: string[]
}

const RESOURCE = 'user'

export const useUserState = createReState<User>(RESOURCE, {} as User)
export const dispatchUserState = createReStateDispatch(RESOURCE)
