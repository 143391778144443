import ReactPlayer, { ReactPlayerProps } from 'react-player'
import styled, { css } from 'styled-components'
import { Animations, themeAlert, themeColor, themeMargin, themePadding } from '../../assets/theme'
import { BaseAlertDetailProps } from './types'

export const Container = styled.div.attrs((props: BaseAlertDetailProps) => {})`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  ${Animations.show}
`

export const Bar = styled.div.attrs((props: BaseAlertDetailProps) => {})`
  height: 60px;
  width: 100%;
  background-image: ${themeAlert};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px ${themePadding('p6')};
`

export const Title = styled.h1.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 25px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.pureWhite.color};
  margin-left: 10px;
`

export const Code = styled.span.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.pureWhite.color};
  margin-left: auto;
`

export const WrapperDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
`

export const Date = styled.span.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.pureWhite.color};
`

export const FormattedCreationDate = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.pureWhite.color};
  margin-top: 5px;
`

export const Hour = styled.span.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.pureWhite.color};
`

export const Name = styled.h3.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  margin-left: 10px;
  font-weight: 600;
`

export const UserName = styled.p.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  margin-left: 10px;
`

export const Description = styled.h3.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  margin-left: 10px;
  font-weight: 600;
`

export const UserDescription = styled.p.attrs((props: BaseAlertDetailProps) => {})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray.color};
  margin-left: 10px;
  margin-top: 10px;
  max-width: 600px;
`

export const ImageBox = styled.div<any>`
  height: 31.5vh;
  max-height: 31.5vh;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.darkGray.color};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const Image = styled.img<any>`
  height: 100%;
`

export const Video = styled(ReactPlayer).attrs((props: ReactPlayerProps) => {})`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
export const BoxHistory = styled.div.attrs((props) => {})`
  margin-left: 10px;
  margin-top: ${themeMargin('m2')};
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;
`

export const TitleHistory = styled.h3.attrs((props) => {})`
  color: ${themeColor('primary')};
  margin-left: ${themeMargin('m2')};
  font-weight: 600;
  font-size: 16px;
  &::first-letter {
    text-transform: capitalize;
  }
`
export const DescriptionHistory = styled.p.attrs((props) => {})`
  margin-left: ${themeMargin('m2')};
  color: ${themeColor('lightGray')};
`
export const BoxIcon = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.background.color};
  border-radius: 50%;
`
const bgTransparent = css`
  background-color: transparent;
`
export const Line = styled.div.attrs((props: { lastPosition?: boolean }) => {})`
  height: 80%;
  min-width: 2px;
  max-width: 2px;
  margin: 5px 13.5px;
  background-color: ${themeColor('lightGray')};
  ${(props) => props.lastPosition && bgTransparent};
  border-radius: 20px;
`
