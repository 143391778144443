import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CardAlert, CardSkeleton } from '../../components'
import { ALERT_TYPE_DICTIONARY } from '../../domain'
import { useAlerts } from '../../hook'
import { filterAlerts, searchStateDispatch } from '../../states'
import * as S from './styles'

interface SearchParams {
  alerta: string
}

interface SearchAlertsProps {}

const NUMBER_OF_LOADING_ITEMS = 7
const FAKE_ITEMS_TO_LOADING = [...new Array(NUMBER_OF_LOADING_ITEMS)].map(() => ({
  id: `skeleton-${Math.random() * 99999}`,
}))

export const SearchAlerts = () => {
  const { alerts, loading, listAlerts } = useAlerts()
  const history = useHistory()
  const { alerta } = useParams<SearchParams>()

  const alertsSearched = useMemo(() => {
    return filterAlerts(alerta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, alerta])

  const handleClick = (id: string) => {
    history?.push(`/alertas/alerta/${id}`)
  }

  useEffect(() => {
    !alerts.length && listAlerts()
    searchStateDispatch(alerta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!alertsSearched.length && alerts.length) {
    return (
      <S.Container>
        <S.NotFound>Nenhum alerta foi encontrado</S.NotFound>
      </S.Container>
    )
  }

  if (loading && !alerts.length) {
    return (
      <S.Container>
        {FAKE_ITEMS_TO_LOADING.map((item) => (
          <CardSkeleton key={item.id} />
        ))}
      </S.Container>
    )
  }

  return (
    <S.Container>
      {alertsSearched.map((alert) => {
        return (
          <CardAlert
            key={alert._id}
            status={alert.status}
            title={ALERT_TYPE_DICTIONARY[alert.type]}
            location={alert.formattedAddress}
            image={alert.type}
            alert={alert.category}
            onClick={() => handleClick(alert._id)}
            haveAttachments={alert.hasAttachments}
            time={alert.formattedCreationDate}
            createdAt={alert.createdAt}
          />
        )
      })}
    </S.Container>
  )
}
