import { css } from 'styled-components'
import { IThemeProps } from '../../types'

export const flexDirection = ['row', 'row-reverse', 'column', 'column-reverse'] as const
export const flexWrap = ['wrap', 'wrap-reverse', 'nowrap', 'initial', 'inherit', 'unset'] as const
export const justifyContent = [
  'baseline',
  'center',
  'end',
  'flex-end',
  'flex-start',
  'inherit',
  'initial',
  'left',
  'right',
  'space-around',
  'space-between',
  'space-evenly',
  'start',
  'stretch',
  'unset',
] as const

const justifyContentStyle = css`
  justify-content: ${({ justifyContent }: IThemeProps) => justifyContent ?? 'initial'};
`

const justifyItems = css`
  justify-items: ${({ justifyItems }: IThemeProps) => justifyItems ?? 'initial'};
`
const justifySelf = css`
  justify-self: ${({ justifySelf }: IThemeProps) => justifySelf ?? 'initial'};
`
const alignSelf = css`
  align-self: ${({ alignSelf }: IThemeProps) => alignSelf ?? 'initial'};
`

const alignContent = css`
  align-content: ${({ alignContent }: IThemeProps) => alignContent ?? 'initial'};
`

const alignItems = css`
  align-items: ${({ alignItems }: IThemeProps) => alignItems ?? 'initial'};
`

const row = css`
  flex-direction: row;
`
const flex = css`
  flex: ${({ flex = 1 }: IThemeProps) => flex};
`
const flexWrapStyle = css`
  flex-wrap: ${({ flexWrap = 'nowrap' }: IThemeProps) => flexWrap};
`
export const flexGroup = css`
  display: flex;
  flex-direction: column;
  position: relative;
  ${(props: IThemeProps) => props.justifyContent && justifyContentStyle};
  ${(props: IThemeProps) => props.justifyItems && justifyItems};
  ${(props: IThemeProps) => props.alignContent && alignContent};
  ${(props: IThemeProps) => props.alignItems && alignItems};
  ${(props: IThemeProps) => props.row && row};
  ${(props: IThemeProps) => props.flex && flex};
  ${(props: IThemeProps) => props.flexWrap && flexWrapStyle};
  ${(props: IThemeProps) => props.justifySelf && justifySelf};
  ${(props: IThemeProps) => props.alignSelf && alignSelf};
`
