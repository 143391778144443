import styled, { css } from 'styled-components'
import background from '../../../assets/images/background-balls.png'
import { Input as InputDefault } from '../../shared'
import { FiX } from 'react-icons/fi'
import { themeBackgroundColorSecondary, variantThemeColor, variantThemeColorHighlight } from '../../../assets/theme'

export const Container = styled.div.attrs((props: any) => {})`
  width: 20%;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  margin: 0 0.5em;
  background-color: ${variantThemeColorHighlight};
  .body {
    background-color: ${themeBackgroundColorSecondary};
  }
  .footer {
    border-color: ${variantThemeColorHighlight};
  }
  color: ${variantThemeColor};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  position: relative;
`

export const Body = styled.div`
  background-image: ${`url(${background})`};
  background-size: 100%;
  background-color: #fbfbfb;
  transition: 0.5s;
  height: ${({ open }: { open: boolean }) => (open ? '40vh' : '0')};
  overflow-y: auto;
`

export const Footer = styled.div.attrs((props: any) => {})`
  box-shadow: 0px 0px 26px #151e1e0f;
  border: 1px solid #f0f3f8;
  transition: 0.5s;
  height: 0;
  ${({ open }: { open?: boolean }) =>
    open &&
    css`
      display: flex;
      align-items: center;
      height: 5vh;
    `};
`

export const Wrapper = styled.div.attrs((props: any) => {})`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  z-index: ${({ zIndex }) => zIndex && 9};
`

export const User = styled.div`
  margin-left: 0.8em;
`

export const Title = styled.p`
  font-size: 0.8em;
  font-weight: 600;
`

export const Date = styled.p`
  font-size: 0.6em;
  color: #c8c8d2;
`

export const BoxMessage = styled.div`
  display: flex;
  justify-content: ${({ squad }: { squad?: boolean }) => (squad ? 'flex-end' : 'flex-start')};
  padding: 1.5% 5%;
  box-sizing: border-box;
`

export const Message = styled.p`
  background: ${({ squad }: { squad?: boolean }) => (squad ? '#2C2C31' : '#f0f3f6')};
  color: ${({ squad }: { squad?: boolean }) => (squad ? '#f0f3f6' : '#2C2C31')};
  border-radius: 8px;
  width: 70%;
  padding: 1em;
  box-sizing: border-box;
  font-size: 10px;
`

export const Input = styled(InputDefault)`
  width: 100%;
  input {
    font-size: 12px;
  }
`

export const Close = styled(FiX)`
  margin-left: 0.8em;
  cursor: pointer;
`

export const Send = styled.div`
  cursor: pointer;
  width: 24px;
  transition: 0.5s;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 1em;
`

export const Click = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
`

export const JoinButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
