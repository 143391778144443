import styled from 'styled-components'
import { themeBackgroundColor, themeColor, themeMargin, themeMarginGroup, themeRadius } from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'
import { BaseCardChatProps } from './types'

export const Container = styled.div.attrs((props: BaseCardChatProps) => {})`
  height: 95px;
  box-sizing: border-box;
  position: relative;
  width: 360px;
  background-color: ${themeBackgroundColor};
  color: ${(props) => (props.restrict ? themeColor('pureWhite') : themeColor('gray'))};
  border-radius: ${themeRadius('normal')};
  display: flex;
  align-items: center;
  ${themeMarginGroup};
  ${animations.show}
  margin-top: ${themeMargin('m2')};
  padding: 0.5em 0;
  &:hover {
    cursor: pointer;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: ${themeColor('pureWhite')}20;
      border-radius: ${themeRadius('normal')};
      top: 0;
      left: 0;
    }
  }
`
export const WrapperImages = styled.div.attrs((props: BaseCardChatProps) => {})`
  width: 80px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserImage = styled.img.attrs((props: BaseCardChatProps) => {})`
  width: 45px;
  height: 45px;
  position: absolute;
  margin-bottom: 10px;
  border-radius: ${themeRadius('big')};
  border: 2px solid ${themeBackgroundColor};
  &:hover {
    z-index: 2;
  }
`
export const AlertImage = styled.div.attrs((props: BaseCardChatProps) => {})`
  width: 38px;
  height: 38px;
  border: 2px solid ${themeBackgroundColor};
  position: absolute;
  margin-top: 20px;
  margin-left: 25px;
  border-radius: ${themeRadius('big')};
  &:hover {
    z-index: 2;
  }
`
export const WrapperText = styled.div.attrs((props: BaseCardChatProps) => {})`
  flex: 1;
`
export const AlertType = styled.h3.attrs((props: BaseCardChatProps) => {})`
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: ${themeColor('primary')};
`
export const UserName = styled.h3.attrs((props: BaseCardChatProps) => {})`
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 2px;
  color: ${themeColor('primary')};
`
export const Message = styled.p.attrs((props: BaseCardChatProps) => {})`
  font-size: 12px;
  max-height: 2em;
  margin-top: 6px;
  overflow: hidden;
  color: ${themeColor('primary')};
`
export const WrapperMessage = styled.div.attrs((props: BaseCardChatProps) => {})`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
export const Time = styled.span.attrs((props: BaseCardChatProps) => {})`
  font-size: 10px;
  padding: 0 10px 0 5px;
  color: ${themeColor('primary')};
`
