import React from 'react'
import SliderImage from 'react-zoom-slider'
import { usePreview } from '../../hook'
import * as S from './styles'
import { FiX } from 'react-icons/fi'

const PreviewImages = () => {
  const { preview, handlePreview } = usePreview()
  if (!preview.length) {
    return null
  }
  return (
    <S.Wrapper>
      <S.Header>
        <FiX size="36px" color="#fff" onClick={() => handlePreview([])} />
      </S.Header>
      <SliderImage
        data={preview.map(i => ({ image: i.location }))}
        width="600px"
        showDescription={true}
        direction="right"
      />
    </S.Wrapper>
  )
}

export default PreviewImages
