import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi'
import styled, { css } from 'styled-components'
import {
  rounded,
  themeMarginGroup,
  themeRadius,
  variantThemeColor,
  variantThemeColorHighlight,
} from '../../../../assets/theme'

import { BaseInputProps } from '../types'
const outlined = css`
  border: 1px solid ${variantThemeColor};
  background-color: transparent;
  color: ${variantThemeColor};
`
const width = css`
  width: ${(props: BaseInputProps) => props.width}px;
`
export const Container = styled.div.attrs((props: BaseInputProps) => {})`
  color: ${variantThemeColor};
  background-color: ${variantThemeColorHighlight};
  border: 1px solid ${variantThemeColorHighlight};
  border-radius: ${themeRadius('normal')};
  width: fit-content;
  height: 40px;
  box-sizing: border-box;
  width: 320px;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${(props) => props.rounded && rounded};
  ${(props) => props.outlined && outlined};
  ${(props) => props.width && width};
  ${themeMarginGroup}
`
export const InputText = styled.input.attrs((props: BaseInputProps) => {})`
  color: inherit;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 0;
  padding-left: 1em;
  outline: none;
  border: none;

  background-color: transparent;
  &::placeholder {
    color: inherit;
    opacity: 0.6;
  }
`
export const IconBox = styled.div`
  color: inherit;
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Lock = styled(FiLock)`
  color: inherit;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin-left: 10px;
  transform: translateY(-1px);
`
export const Visible = styled(FiEye)`
  color: inherit;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin-left: 5px;
  margin-right: 5px;
  transform: translateY(-1px);
  position: absolute;
`
export const Hidden = styled(FiEyeOff)`
  color: inherit;
  position: absolute;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  margin-left: 5px;
  margin-right: 5px;
  transform: translateY(-1px);
`
