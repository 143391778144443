import { css } from 'styled-components'
import { variantThemeColor, variantThemeColorHighlight } from '..'

export const outlined = css`
  background-color: transparent;
  color: ${variantThemeColor};
  border-color: ${variantThemeColor};
  &:hover {
    background-color: transparent;
  }
`
export const shine = css`
  box-shadow: 0 0 6px ${variantThemeColor}29;
`
export const shineHighlight = css`
  box-shadow: 0 0 6px ${variantThemeColorHighlight}29;
`
