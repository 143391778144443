import styled from 'styled-components'
import { BaseOptionProps } from './types'
import { Input } from '..'

export const Container = styled.div.attrs((props: BaseOptionProps) => {})`
  display: flex;
`

export const CustomInput = styled(Input)`
  border: 1px solid #4C5963;
  border-top-left-radius: 8px;
`
