import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CardAlert, CardSkeleton } from '..'
import { ALERT_TYPE_DICTIONARY } from '../../domain'
import * as S from './styles'
import { BaseAlertListProps } from './types'

const NUMBER_OF_LOADING_ITEMS = 7
const FAKE_ITEMS_TO_LOADING = [...new Array(NUMBER_OF_LOADING_ITEMS)].map(() => ({
  id: `skeleton-${Math.random() * 99999}`,
}))

const AlertList: React.FC<BaseAlertListProps> = ({ loading, alerts }) => {
  const history = useHistory()

  const handleNavigateDetail = useCallback(
    (id: string) => {
      history.push(`/alertas/alerta/${id}`)
    },
    [history]
  )

  if (loading && !alerts.length) {
    return (
      <S.Container>
        {FAKE_ITEMS_TO_LOADING.map(item => (
          <CardSkeleton key={item.id} />
        ))}
      </S.Container>
    )
  }

  return (
    <S.Container>
      {loading && <div>loading</div>}
      {alerts.map(item => {
        return (
          <CardAlert
            key={item._id}
            status={item.status}
            title={ALERT_TYPE_DICTIONARY[item.type]}
            location={item.formattedAddress}
            image={item.type}
            alert={item.category}
            onClick={() => handleNavigateDetail(item._id)}
            haveAttachments={item.hasAttachments}
            time={item.formattedCreationDate}
            createdAt={item.createdAt}
          />
        )
      })}
    </S.Container>
  )
}

export default AlertList
