import { createReState, createReStateSelect, createReStateDispatch, createGetReState } from '@raulpesilva/re-state'
import LocalStorage from '../../local-storage'

type PlaylistTime = number

const KEY = 'playlistTime'

const getInitialState = () => {
  const initialStateStorage = LocalStorage.getItem(KEY) ?? '1200'
  const initialValue = Number(initialStateStorage)
  return initialValue
}
export const usePlaylistTime = createReState<PlaylistTime>(KEY, getInitialState())
export const usePlaylistTimeSelect = createReStateSelect<PlaylistTime>(KEY)
export const dispatchPlaylistTime = createReStateDispatch<PlaylistTime>(KEY)
export const getPlaylistTime = createGetReState<PlaylistTime>(KEY)
export const resetPlaylistTime = () => dispatchPlaylistTime(getInitialState())
export const setPlaylistTime = (value: PlaylistTime) => {
  LocalStorage.setItem(KEY, value.toString())
  dispatchPlaylistTime(value)
}
