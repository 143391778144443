import styled from 'styled-components'
import { BaseMessagesRowProps } from './types'

export const Container = styled.div.attrs((props: BaseMessagesRowProps) => {})`
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 100;
`
