import styled, { css } from 'styled-components'
import { flexGroup, themeAnimation, themeMarginGroup, themePaddingGroup } from '../../../../assets/theme'
import { BaseWrapperProps } from './types'

const BaseWrapper = css`
  box-sizing: border-box;
`

export const Container = styled.div.attrs((props: BaseWrapperProps) => {
  return props
})`
  ${themePaddingGroup};
  ${themeMarginGroup};
  ${BaseWrapper};
  background-color: transparent;
  ${flexGroup};
  ${(props) => props.animation && themeAnimation};
  position: relative;
`
