import {
  createReState,
  createReStateDispatch,
  Selector,
  createGetReState,
  createReStateSelect,
} from '@raulpesilva/re-state'
import LocalStorage from '../../local-storage'
import { Camera, getCameraById } from '../useCameraState'
import { setHasNewPlaylist } from '../useHasNewPlaylist'

type MosaicProps = Camera[]
export type MosaicPositions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export const mosaicKey = 'mosaic'
const getInitialState = (): MosaicProps => {
  const mosaicData = LocalStorage.getItem(mosaicKey)
  if (mosaicData) return JSON.parse(mosaicData)
  return []
}

export const useMosaicState = createReState<MosaicProps>(mosaicKey, getInitialState())
export const useMosaicSelect = createReStateSelect<MosaicProps>(mosaicKey)
export const dispatchMosaic = createReStateDispatch<MosaicProps>(mosaicKey)
export const getMosaic = createGetReState<MosaicProps>(mosaicKey)

export const mosaicSelector: Selector<{ [mosaicKey]: MosaicProps }, MosaicProps> = ({ mosaic }) => mosaic
export const isSameCameras = (prevState: MosaicProps, state: MosaicProps) => {
  if (prevState.length !== state.length) return false
  for (const position in state) {
    if (prevState[position]._id !== state[position]._id) {
      return false
    }
  }
  return true
}

export const replaceAllMosaic = (cameras: MosaicProps) => {
  const camerasMosaic = [...cameras.slice(0, 9)]
  dispatchMosaic(camerasMosaic)
  LocalStorage.setItem(mosaicKey, JSON.stringify(camerasMosaic))
}

export const cameraIsAlreadyInMosaic = (cameraId?: Camera['_id']) => {
  const mosaicCameras = getMosaic()
  return mosaicCameras.some(cam => cam._id === cameraId)
}

export const replaceCameraPosition = (position: MosaicPositions, cameraId?: Camera['_id']) => {
  if (!cameraId) return

  const camera = getCameraById(cameraId)
  const mosaicCameras = getMosaic()

  if (!camera || cameraIsAlreadyInMosaic(cameraId)) return

  const newMosaic = [...mosaicCameras]
  newMosaic[position] = camera
  LocalStorage.setItem(mosaicKey, JSON.stringify(newMosaic))
  dispatchMosaic(newMosaic)
  setHasNewPlaylist()
}
