import styled from 'styled-components'
import {
  themeColor,
  themeColorHighlight,
  themeMarginGroup,
  themePaddingGroup,
  themeRadius,
} from '../../../../assets/theme'
import { BaseFormProps } from './types'

export const Container = styled.form.attrs((props: BaseFormProps) => {})`
  width: 468px;
  background-color: ${themeColorHighlight('primary')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${themeRadius('normal')};
  position: relative;
  overflow: hidden;
  ${themeMarginGroup};
  ${themePaddingGroup};
`
export const Highlight1 = styled.div.attrs((props: BaseFormProps) => {})`
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  top: -200px;
  right: -200px;
  background-color: ${themeColor('primary')}0D;
`
export const Highlight2 = styled.div.attrs((props: BaseFormProps) => {})`
  position: absolute;
  background-color: ${themeColor('primary')}0D;
  width: 302px;
  height: 302px;
  border-radius: 100%;
  top: -151px;
  right: -151px;
`
