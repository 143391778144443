import { ReactElement } from 'react'
import styled from 'styled-components'
import { themeColor } from '../../assets/theme'
import { ImContrast } from 'react-icons/im'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`

export const Title = styled.h1.attrs((props: ReactElement<HTMLTitleElement>) => {})`
  color: ${themeColor('primary')};
  font-size: 36px;
  font-weight: 700;
  margin-top: 2em;
  margin-bottom: 1em;
`
export const Label = styled.label.attrs((props: ReactElement<HTMLTitleElement>) => {})`
  color: ${themeColor('primary')};
  font-size: 16px;
  font-weight: 600;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  width: 380px;
`
export const Theme = styled(ImContrast).attrs((props: ReactElement<HTMLTitleElement>) => {})`
  color: ${themeColor('primary')};
  position: absolute;
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
  right: 20px;
  top: 10px;
  cursor: pointer;
`

export const Image = styled.img`
  width: 80%;
`
