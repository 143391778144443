import React from 'react'
import { BaseButtonProps } from '../types'
import * as Styled from './styles'

const PrimaryButton: React.FC<BaseButtonProps> = (props) => {
  const { leftIcon: LeftIcon, rightIcon: RightIcon, label, isLoading, disabled, size, ...rest } = props
  return (
    <Styled.Container
      row
      {...rest}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      withIconLeft={LeftIcon || isLoading}
      withIconRight={RightIcon}
    >
      {isLoading && (
        <Styled.IconBox>
          <Styled.Loading />
        </Styled.IconBox>
      )}
      {!isLoading && LeftIcon && (
        <Styled.IconBox>
          <LeftIcon />
        </Styled.IconBox>
      )}
      {!!label ? label : ''}
      {RightIcon && (
        <Styled.IconBox>
          <RightIcon />
        </Styled.IconBox>
      )}
    </Styled.Container>
  )
}

PrimaryButton.defaultProps = {
  label: 'placeholder',
  variant: 'primary',
}

export default PrimaryButton
