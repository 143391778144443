import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CustomMap } from '../../components'
import { Pin } from '../../components/shared/Pin'
import { useCameras } from '../../hook/useCameras'
import { getLocationByCameraId } from '../../states'
import * as S from './styles'

const Maps = () => {
  const { cameras } = useCameras()
  const history = useHistory()
  const { cameraId } = useParams<{ cameraId: string }>()

  const handleClickPinCamera = useCallback(
    (id: string) => {
      history?.push(`/cameras/camera/${id}`)
    },
    [history]
  )

  const initialLocation = useMemo(() => {
    const cameraLat = cameras?.[0]?.location.coordinates[0]
    const cameraLng = cameras?.[0]?.location.coordinates[1]
    return {
      lat: cameraLat || defaultProps.center.lat,
      lng: cameraLng || defaultProps.center.lng,
    }
  }, [cameras])

  const centerLocation = useMemo(() => {
    const location = getLocationByCameraId(cameraId)

    if (location.latitude && location.longitude) {
      return { lat: location.latitude, lng: location.longitude }
    }
    return initialLocation
  }, [cameraId, initialLocation])

  return (
    <S.Container>
      <S.Wrapper>
        <CustomMap defaultCenter={initialLocation} center={centerLocation}>
          {cameras.map((camera) => (
            <Pin
              key={camera._id}
              onClick={() => handleClickPinCamera(camera._id)}
              lat={camera.location.coordinates[0]}
              lng={camera.location.coordinates[1]}
              type={camera.restrictedAccess ? 'cameraRestrict' : 'camera'}
            />
          ))}
        </CustomMap>
      </S.Wrapper>
    </S.Container>
  )
}

const defaultProps = {
  center: { lat: -23.5681, lng: -46.6492 },
}

export default Maps
