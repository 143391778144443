import React from 'react'
import * as Styled from './styles'
import { BaseCardChatProps } from './types'
import IlustraType from '../../Ilustra-type'

const CardChat: React.FC<BaseCardChatProps> = ({
  userImage,
  alertImage,
  alertType,
  alertStatus,
  time,
  userName,
  message,
  ...rest
}) => {
  return (
    <Styled.Container {...rest}>
      <Styled.WrapperImages>
        <Styled.UserImage src={userImage} />
        <Styled.AlertImage>
          <IlustraType type={alertImage} size={38} />
        </Styled.AlertImage>
      </Styled.WrapperImages>
      <Styled.WrapperText>
        <Styled.AlertType alertStatus={alertStatus}>{alertType}</Styled.AlertType>
        <Styled.UserName>Conversa com {userName}</Styled.UserName>
        <Styled.WrapperMessage>
          <Styled.Message>{message}</Styled.Message>
          <Styled.Time>{time}</Styled.Time>
        </Styled.WrapperMessage>
      </Styled.WrapperText>
    </Styled.Container>
  )
}

export default CardChat
