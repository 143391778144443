import styled, { css } from 'styled-components'
import { themePadding } from '../../assets/theme'

const selected = css`
  justify-content: flex-end;
  padding-right: ${themePadding('p5')};
`
export const WrapperVideo = styled.div.attrs((props: any) => {})`
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-evenly;
  align-content: flex-start;
  width: calc(168vh - 80px);
  max-width: calc(168vh - 80px);
  box-sizing: border-box;
  height: calc(100vh - 80px);
  overflow: hidden;

  ${(props) => props.selected && selected}
`
export const Container = styled.div.attrs((props: any) => {})`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
