import styled from 'styled-components'
import { Animations, themeColorHighlight } from '../../../../assets/theme'
import { BaseOptionProps } from './types'

export const Container = styled.div.attrs((props: BaseOptionProps) => {})`
  display: flex;
  position: relative;
`

export const Options = styled.div<any>`
  width: 80px;
  min-width: 120px;
  box-sizing: border-box;
  height: fit-content;
  min-height: fit-content;
  bottom: -100%;
  margin-left: 10px;
  border-radius: 4px;
  background-color: ${themeColorHighlight('primary')};
  color: ${themeColorHighlight('secondary')};
  position: absolute;
  z-index: 1;
  ${Animations.appear}
`
export const Option = styled.div<any>`
  width: 100%;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  transition-duration: 300ms;
  border-radius: 4px;
  &:hover {
    background-color: ${themeColorHighlight('secondary')}20;
    cursor: pointer;
  }
`
