import React, { useMemo } from 'react'
import { ContainerProps } from '../../../../core/types'
import { mergeObject } from '../../../../core/utils'
import { useStopPropagation } from '../../../utils'
import s from './styles.module.css'

interface BaseProps extends ContainerProps<{ title: string }> {
  children: React.ReactNode
}

export const BaseToast = ({ contentStyle, children, config }: BaseProps) => {
  const mergedContent = useMemo(() => mergeObject(contentStyle, config?.contentStyle), [config, contentStyle])

  const handleStopPropagation = useStopPropagation()

  return (
    <div className={s.content} style={mergedContent} onClick={handleStopPropagation}>
      {children}
    </div>
  )
}
