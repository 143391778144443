import { FiPaperclip } from 'react-icons/fi'
import styled, { css } from 'styled-components'
import { themeAlert, themeColor, themeMarginGroup, themeRadius, themeStatus } from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'
import { BaseCardAlertProps } from './types'

export const Container = styled.div.attrs((props: BaseCardAlertProps) => {})`
  height: 70px;
  min-height: 70px;
  box-sizing: border-box;
  width: 360px;
  background-image: ${themeAlert};
  color: ${themeColor('pureWhite')};
  border-radius: ${themeRadius('normal')};
  display: flex;
  align-items: center;
  position: relative;
  ${themeMarginGroup};
  ${animations.fromLeft}

  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`
export const Title = styled.h3`
  font-weight: 600;
  margin: 0;
  height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Description = styled.p`
  font-size: 10px;
  margin-top: 5px;
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Image = styled.img.attrs((props: BaseCardAlertProps) => {})`
  margin: 0 10px;
  box-sizing: border-box;
  width: 57px;
  height: 57px;
  min-width: 57px;
  min-height: 57px;
  border: 3px solid ${themeStatus};
  border-radius: ${themeRadius('big')};
`

export const Wrapper = styled.div`
  flex: 1;
`
export const Time = styled.span`
  font-size: 8px;
`
export const Date = styled.span`
  font-size: 12px;
`
const dontHaveAttachments = css`
  ${Time} {
    padding-top: 0.5rem;
  }
`
export const WrapperTime = styled.div.attrs((props: BaseCardAlertProps) => {})`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  ${props => !props.haveAttachments && dontHaveAttachments}
`
export const Attachments = styled(FiPaperclip)`
  margin: 0.5rem 0;
`
