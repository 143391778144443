import { FiMapPin, FiMonitor, FiPaperclip } from 'react-icons/fi'
import styled, { css } from 'styled-components'
import {
  shine,
  themeBackgroundColor,
  themeCamera,
  themeCameraHover,
  themeColor,
  themeColorHighlight,
  themeMarginGroup,
  themeRadius,
} from '../../../../assets/theme'
import animations from '../../../../assets/theme/animations'
import { BaseCardAlertProps } from '../CardAlert/types'
import { BaseCardCameraProps } from './types'

export const FloatBox = styled.div.attrs((props: BaseCardAlertProps) => {})`
  border-radius: ${themeRadius('normal')};
  background-color: ${themeBackgroundColor};
  width: 479px;
  height: 320px;
  min-width: 479px;
  min-height: 320px;
  position: absolute;
  z-index: 99;
  left: 410px;
  top: ${({ floatBoxPositionY }) => floatBoxPositionY - 200}px;
  display: flex;
  flex-direction: column;

  ${shine}
  ${animations.growUp}
  &:hover {
    cursor: initial;
    filter: none;
  }
`

export const Container = styled.div.attrs((props: BaseCardCameraProps) => props)`
  height: 70px;
  min-height: 70px;
  box-sizing: border-box;
  position: relative;
  width: 360px;
  background-image: ${themeCamera};
  color: ${props => (props?.restrict ? themeColor('pureWhite') : themeColor('gray'))};
  border-radius: ${themeRadius('normal')};
  border: 0.5px solid ${props => (props.restrict ? themeColor('gray') : themeColorHighlight('primary'))};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: visible;
  ${themeMarginGroup};
  ${animations.fromLeft}
  transition-duration: 300ms;
  &:hover {
    cursor: pointer;
    background-image: ${themeCameraHover};
  }
`

export const Title = styled.h3`
  font-weight: 600;
  margin: 0;
  height: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Description = styled.p`
  font-size: 10px;
  margin-top: 5px;
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Image = styled.img.attrs((props: BaseCardCameraProps) => {})`
  margin: 0 10px;
  box-sizing: border-box;
  width: 57px;
  height: 57px;
  min-width: 57px;
  min-height: 57px;
  border-radius: ${themeRadius('big')};
`

export const Wrapper = styled.div`
  flex: 1;
`

const highlightIcon = css`
  color: ${themeColor('green')};
`
export const Pc = styled(FiMonitor).attrs((props: BaseCardCameraProps) => {})`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 8px 10px 12px;
  width: 17px;
  height: 17px;
  border-top-left-radius: ${themeRadius('big')};
  border-bottom-left-radius: ${themeRadius('big')};
  background-color: ${themeColor('gray')};
  color: ${themeColor('pureWhite')};
  box-sizing: unset;
  ${props => props.open && highlightIcon}
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`

export const Pin = styled(FiMapPin).attrs((props: BaseCardCameraProps) => {})`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 8px 10px 12px;
  width: 17px;
  height: 17px;
  border-top-left-radius: ${themeRadius('big')};
  border-bottom-left-radius: ${themeRadius('big')};
  background-color: ${themeColor('gray')};
  color: ${themeColor('pureWhite')};
  box-sizing: unset;
  ${props => props.selected && highlightIcon}
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`

export const WrapperIcon = styled.div.attrs((props: BaseCardCameraProps) => {})`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;
  margin-left: 1em;
  z-index: 1;
`
export const Attachments = styled(FiPaperclip)``

export const TitleBox = styled.h3.attrs((props: BaseCardAlertProps) => {})`
  color: ${themeColor('lightGray')};
  padding-left: ${({ theme }) => theme.padding.p3};
  padding-top: ${({ theme }) => theme.padding.p4};
`

export const WrapperVideos = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
`

export const VideoBox = styled.div.attrs((props: BaseCardAlertProps) => {})`
  width: 147px;
  height: 83px;
  background-color: ${themeColorHighlight('primary')};
  border-radius: ${themeRadius('normal')};
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
`
