import { useCallback, useEffect, useState } from 'react'
import LocalStorage from '../../local-storage'
import { resetPlaylistTime, selectCamerasState } from '../../states'
import { resetHasNewPlaylist } from '../../states/useHasNewPlaylist'
import { replaceAllMosaic } from '../../states/useMosaicState'
import { insertManyPlaylist, usePlaylistsSelect } from '../../states/usePlaylistsState'

interface ISetCameras {
  position: number
}

const PLAYLIST_POSITION_SOURCE = 'playlistPosition'

export const usePlaylist = () => {
  const cameras = selectCamerasState()
  const [playlistPosition, setPlaylistPosition] = useState(LocalStorage.getItem(PLAYLIST_POSITION_SOURCE) ?? 1)
  const playlists = usePlaylistsSelect()
  const [added, setAdded] = useState(false)

  useEffect(() => {
    if (!added && cameras.length && !playlists.length) {
      let presetPlaylist = []
      for (let position = 1; position <= Math.ceil(cameras.length / 9); position++) {
        const initialPosition = position * 9 - 9
        const endPosition = position * 9
        const playlist = cameras.slice(initialPosition, endPosition)
        presetPlaylist.push(playlist)
      }
      insertManyPlaylist(presetPlaylist)
      setAdded(true)
    }
  }, [added, cameras, playlists])

  const setCameras = useCallback(
    ({ position }: ISetCameras) => {
      replaceAllMosaic(playlists[position])
      setPlaylistPosition(position)
      LocalStorage.setItem(PLAYLIST_POSITION_SOURCE, position.toString())
    },
    [playlists]
  )

  const nextPlaylist = useCallback(() => {
    const intentPosition = Number(playlistPosition) + 1
    const fistPosition = 0
    const lastPosition = playlists.length - 1
    const position = intentPosition > lastPosition || intentPosition < fistPosition ? fistPosition : intentPosition
    setCameras({ position })
    resetPlaylistTime()
    resetHasNewPlaylist()
  }, [playlists.length, playlistPosition, setCameras])

  const previousPlaylist = useCallback(() => {
    const intentPosition = Number(playlistPosition) - 1
    const fistPosition = 0
    const lastPosition = playlists.length - 1
    const position = intentPosition < fistPosition || intentPosition > lastPosition ? lastPosition : intentPosition
    setCameras({ position })
    resetPlaylistTime()
    resetHasNewPlaylist()
  }, [playlists.length, playlistPosition, setCameras])

  return { nextPlaylist, previousPlaylist, playlistPosition, setPlaylistPosition }
}
