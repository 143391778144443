import React, { DragEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useMemo } from 'react'
import { Camera } from '../../../../states'
import {
  cameraIsAlreadyInMosaic,
  MosaicPositions,
  useMosaicSelect,
  replaceCameraPosition,
} from '../../../../states/useMosaicState'
import * as Styled from './styles'
import { BaseCardCameraProps } from './types'

const MIN_Y_POSITION = window.innerHeight - 300

const FloatBox = ({
  floatBoxPositionY,
  handleCloseFloatBox,
  cameraId,
}: {
  handleCloseFloatBox: (e?: MouseEvent) => void
  floatBoxPositionY: number
  cameraId?: Camera['_id']
}) => {
  useEffect(() => {
    window.addEventListener('click', handleCloseFloatBox)
    return () => window.removeEventListener('click', handleCloseFloatBox)
  }, [handleCloseFloatBox])

  const handleClickBoxCamera = useCallback((e: Event) => {
    e.stopPropagation()
  }, [])

  const handleCloseBox = useCallback(
    index => {
      handleCloseFloatBox()
      replaceCameraPosition(index as MosaicPositions, cameraId)
    },
    [handleCloseFloatBox, cameraId]
  )

  return (
    <Styled.FloatBox
      floatBoxPositionY={floatBoxPositionY < MIN_Y_POSITION ? floatBoxPositionY : MIN_Y_POSITION}
      onClick={handleClickBoxCamera}
    >
      <Styled.TitleBox>Selecione o lugar da camera</Styled.TitleBox>
      <Styled.WrapperVideos>
        {[...new Array(9)].map((_, index) => (
          <Styled.VideoBox key={index} onClick={() => handleCloseBox(index)} />
        ))}
      </Styled.WrapperVideos>
    </Styled.FloatBox>
  )
}

const CardCamera: React.FC<BaseCardCameraProps> = ({
  image,
  title,
  location,
  showCase,
  withLocation,
  id,
  ...props
}) => {
  const cameraRef = useRef<HTMLDivElement>(null)

  const [togglePositionCamera, setTogglePositionCamera] = useState(false)
  const [floatBoxPositionY, setFloatBoxPositionY] = useState(150)
  const camerasInMosaic = useMosaicSelect()

  const handleOpenFloatBox = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setFloatBoxPositionY(e.clientY)
      setTogglePositionCamera(prev => !prev)
    },
    [setTogglePositionCamera]
  )

  const handleCloseFloatBox = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation()
      setTogglePositionCamera(false)
    },
    [setTogglePositionCamera]
  )

  useEffect(() => {
    if (cameraRef.current && props.selected) cameraRef.current.scrollIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('id', id as string)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAlreadyInMosaic = useMemo(() => cameraIsAlreadyInMosaic(id), [camerasInMosaic])
  return (
    <>
      <Styled.Container {...props} ref={cameraRef} draggable onDragStart={handleDrag}>
        <Styled.Image src={image} alt={title} />
        <Styled.Wrapper>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Description>{location}</Styled.Description>
        </Styled.Wrapper>
        {!showCase && (
          <Styled.WrapperIcon>
            <Styled.Pc open={isAlreadyInMosaic || togglePositionCamera} onClick={handleOpenFloatBox} />
          </Styled.WrapperIcon>
        )}
        {withLocation && (
          <Styled.WrapperIcon>
            <Styled.Pin selected={props.selected} />
          </Styled.WrapperIcon>
        )}
      </Styled.Container>
      {togglePositionCamera && (
        <FloatBox floatBoxPositionY={floatBoxPositionY} cameraId={id} handleCloseFloatBox={handleCloseFloatBox} />
      )}
    </>
  )
}

export default CardCamera
