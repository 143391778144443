import { FaPause, FaPlay } from 'react-icons/fa'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import styled from 'styled-components'
import { themeColor, themeColorHighlight } from '../../assets/theme'
import animations from '../../assets/theme/animations'

export const Container = styled.div<any>`
  width: fit-content;
  height: fit-content;
  background-color: ${themeColorHighlight('primary')};
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  & > button {
    margin-top: 10px;
  }
`
export const Thumb = styled.img<any>`
  width: 2.8vw;
  height: 2.8vw;
  max-width: 55px;
  max-height: 55px;
  border-radius: 8px;
  margin: 0 auto;
  border: 1px solid ${themeColor('tertiary')};
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
`

export const Time = styled.span<any>`
  color: ${themeColor('primary')};
  text-align: center;
  font-size: 1rem;
`

export const EditTime = styled.input<any>`
  width: 55px;
  height: 20px;
  border-radius: 8px;
  border: 1px solid ${themeColor('tertiary')};
  outline: none;
  background-color: ${themeColor('primary')}20;
  color: ${themeColor('primary')};
  text-align: center;
  box-sizing: border-box;
  font-size: 0.7vw;
  font-weight: bold;
`

export const Pause = styled(FaPause).attrs((props: any) => {})`
  font-size: 12px;
  ${animations.appear}
  &:hover {
    cursor: pointer;
    color: ${themeColorHighlight('secondary')}dc;
  }
`

export const Play = styled(FaPlay).attrs((props: any) => {})`
  font-size: 12px;
  ${animations.appear}
  &:hover {
    cursor: pointer;
    color: ${themeColorHighlight('secondary')}dc;
  }
`

export const ChevronsLeft = styled(FiChevronsLeft)<any>``

export const ChevronsRight = styled(FiChevronsRight)<any>``

export const WrapperButtons = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

export const GhostButton = styled.button<any>`
  height: 25px;
  width: ${props => props?.width ?? '25px'};
  background-color: ${themeColor('primary')}20;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  color: ${themeColor('primary')};
  &:hover {
    background-color: ${themeColor('primary')}80;
    cursor: pointer;
  }
`
