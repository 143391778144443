import styled from 'styled-components'

export const Container = styled.div`
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
export const Pin = styled.img`
  cursor: pointer;
  &:hover {
    z-index: 1;
  }
`
