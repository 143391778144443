import styled from 'styled-components'
import GoogleMapReact, { Props } from 'google-map-react'

import lightTheme from '../../assets/theme/mapTheme.json'
import darkTheme from '../../assets/theme/mapThemeDark.json'

interface MapProps extends Props {
  darkMode: boolean
}

export const Container = styled(GoogleMapReact).attrs(
  (props: MapProps) =>
    ({
      ...props,
      bootstrapURLKeys: {
        key: 'AIzaSyCLpwPw28vHKExS-qDP6uMyF7LWz1B87oY' as string,
      },
      defaultZoom: 15,
      yesIWantToUseGoogleMapApiInternals: true,
      options: {
        styles: props.darkMode ? darkTheme : lightTheme,
      },
    } as MapProps)
)``
