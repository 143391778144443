import { css } from 'styled-components'
import { IMargin, IThemeProps } from '../../types'

export const margin = {
  m0: '0',
  m1: '0.25rem',
  m2: '0.5rem',
  m3: '0.75rem',
  m4: '1rem',
  m5: '1.25rem',
  m6: '1.5rem',
  m7: '1.75rem',
  m8: '2rem',
  m9: '2.5rem',
  m10: '3rem',
  auto: 'auto',
}
export const themeMargin = (margin: IMargin) => ({ theme }: IThemeProps) => theme.margin?.[margin ?? 'm0']

const marginAll = css`
  margin: ${({ theme, m }: IThemeProps) => theme.margin?.[m ?? 'm0']};
`

const marginLeft = css`
  margin-left: ${({ theme, ml }: IThemeProps) => theme.margin?.[ml ?? 'm0']};
`

const marginRight = css`
  margin-right: ${({ theme, mr }: IThemeProps) => theme.margin?.[mr ?? 'm0']};
`

const marginTop = css`
  margin-top: ${({ theme, mt }: IThemeProps) => theme.margin?.[mt ?? 'm0']};
`

const marginBottom = css`
  margin-bottom: ${({ theme, mb }: IThemeProps) => theme.margin?.[mb ?? 'm0']};
`

const marginHorizontal = css`
  margin-left: ${({ theme, mx }: IThemeProps) => theme.margin?.[mx ?? 'm0']};
  margin-right: ${({ theme, mx }: IThemeProps) => theme.margin?.[mx ?? 'm0']};
`

const marginVertical = css`
  margin-top: ${({ theme, my }: IThemeProps) => theme.margin?.[my ?? 'm0']};
  margin-bottom: ${({ theme, my }: IThemeProps) => theme.margin?.[my ?? 'm0']};
`

export const themeMarginGroup = css`
  ${(props: IThemeProps) => props.m && marginAll};
  ${(props: IThemeProps) => props.ml && marginLeft};
  ${(props: IThemeProps) => props.mr && marginRight};
  ${(props: IThemeProps) => props.mt && marginTop};
  ${(props: IThemeProps) => props.mb && marginBottom};
  ${(props: IThemeProps) => props.mx && marginHorizontal};
  ${(props: IThemeProps) => props.my && marginVertical};
`
