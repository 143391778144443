import { motion } from 'framer-motion'
import { FiArrowDown, FiArrowLeft, FiArrowRight, FiArrowUp, FiRotateCcw, FiZoomIn, FiZoomOut } from 'react-icons/fi'
import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'
import { themeColor, themeColorHighlight, themeMargin, themeRadius } from '../../../../assets/theme'

const selected = css`
  width: 89%;
  position: absolute;
  right: ${themeMargin('m3')};
`

export const Container = styled(motion.div)<any>`
  position: relative;
  width: 32%;
  border-radius: ${themeRadius('small')};
  margin-bottom: 0.8%;
  overflow: hidden;
  ${props => props.selected && selected};
`

type WrapperProps = { ready: boolean }

export const Wrapper = styled.div.attrs((props: WrapperProps) => {})`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background-color: ${themeColorHighlight('primary')};
  overflow: hidden;
`

export const Video = styled(ReactPlayer)<any>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scale(${props => props.zoom});
  transition-duration: 400ms;
`

export const Detail = styled.div<any>`
  padding: 8px;
  border-radius: ${themeRadius('small')};
  box-sizing: border-box;
  max-width: 100%;
  background-color: ${themeColorHighlight('primary')}f0;
  color: ${themeColor('primary')};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: Appearing 500ms cubic-bezier(0, 0.41, 0.3, 1.15) forwards;
  @keyframes Appearing {
    to {
      opacity: 1;
    }
  }
`

export const Name = styled.h1<any>`
  font-weight: 600;
`

export const Address = styled.p<any>``

export const BoxZoom = styled.div<any>`
  padding: 0 ${themeMargin('m3')};
  height: 50px;
  background-color: ${themeColorHighlight('primary')};
  width: fit-content;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  align-items: center;
`

export const ZoomValue = styled.span<any>`
  font-size: 20px;
  color: ${themeColor('darkGray')};
  margin: 0 ${themeMargin('m3')};
  user-select: none;
`

const disabled = css`
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
`

export const Reset = styled(FiRotateCcw)<any>`
  width: 24px;
  height: 24px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`

export const ZoomIn = styled(FiZoomIn)<any>`
  width: 24px;
  height: 24px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
export const ZoomOut = styled(FiZoomOut)<any>`
  width: 24px;
  height: 24px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
export const ArrowLeft = styled(FiArrowLeft)<any>`
  width: 24px;
  height: 24px;
  margin-left: 24px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
export const ArrowRight = styled(FiArrowRight)<any>`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
export const ArrowUp = styled(FiArrowUp)<any>`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
export const ArrowDown = styled(FiArrowDown)<any>`
  width: 24px;
  height: 24px;
  margin-left: 10px;
  transition-duration: 250ms;
  padding: 5px;
  border-radius: 8px;
  box-sizing: unset;
  &:hover {
    cursor: pointer;
    background-color: ${themeColor('primary')}20;
  }
  ${props => props.disabled && disabled};
`
