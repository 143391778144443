import styled from 'styled-components'

export const Container = styled.div`
  width: 512px;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  color: #4c5963;
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
`

export const Description = styled.p`
  color: #4c5963;
  margin-top: 16px;
  margin-bottom: 16px;
  flex: 1;
`

export const WrapperOptions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  & > button {
    width: calc(50% - 15px);
  }
`
export const InputField = styled.textarea`
  width: 100%;
  height: 80px;
  border-radius: 8px;
  margin-bottom: 10px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
`
